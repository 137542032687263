import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import Modal from "../components/Modal/Modal";
import { hideModal } from "../redux/modalStore";
import { useDispatch, useSelector } from "react-redux";
import DatePickerField from "../components/FormikComponents/DatePickerField";
import Textarea from "../components/Textarea/Textarea";
import styles from "./NewFindingModal.module.scss";
import Button from "../components/Button/Button";
import Input from "../components/Input/Input";
import FormikCheckbox from "../components/FormikComponents/FormikCheckBox/FormikCheckBox";
import FormikUpload from "../components/FormikComponents/FormikUpload/FormikUpload";
import Select from "../components/Select/Select";
import _ from "lodash";
import axiosInstance from "../utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import { getOrganizationData } from "../actions/organization";
import * as Yup from "yup";

const NewFindingModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { risksData } = useSelector((state) => state.organization);

  const openedModal = useSelector((state) => state.modal.openedModal);

  const { orgId } = useParams();
  const sessionToken = localStorage.getItem("session_token");

  const risksArray = Object.values(risksData);
  const sortedRisks = _.sortBy(risksArray, "risk_id");

  const validationSchema = Yup.object().shape({
    description: Yup.string().required("Please provide a description."),
    reference: Yup.string().required("Reference cannot be empty."),
    chapter: Yup.string().required("Please select an associated chapter."),
    risk: Yup.string().required("Please select an associated risk."),
    attachments: Yup.array()
      .of(Yup.string())
      .required("File upload is required"),
  });

  let chapterOptions = sortedRisks.map((risk) => ({
    id: risk.qms_id,
    label: risk.qms_id + " " + risk.qms_description,
  }));

  chapterOptions = _.uniqBy(chapterOptions, "id");

  const riskOptions = sortedRisks.map((risk) => ({
    id: risk.risk_num, // This is the risk_num
    reference: risk.risk_id,
    label: `${risk.risk_id} ${risk.description}`, // This is what the user sees and selects
  }));

  const handleCloseModal = () => {
    dispatch(hideModal(null));
  };

  const formatDate = (inputDate) => {
    if (!inputDate) {
      return new Date().toISOString().split("T")[0];
    }
    return new Date(inputDate).toISOString().split("T")[0];
  };

  const handleSubmit = async (values, actions) => {
    // Search for the selected risk by matching the full string in values.risk
    const selectedRisk = riskOptions.find((risk) => risk.label === values.risk);

    // Extract risk_num from the selected risk
    const riskNumToSend = selectedRisk ? selectedRisk.id : null;

    if (!riskNumToSend) {
      console.error("Selected risk is not found in riskOptions.");
      actions.setSubmitting(false);
      setLoading(false);
      return;
    }

    // Prepare the data to send, including risk_num as risk
    const { chapter, ...valuesToSend } = {
      ...values,
      date: formatDate(values.date),
      risk: riskNumToSend, // Use the extracted risk_num here
    };

    const options = {
      method: "POST",
      url: `/nocodb/findings?org_id=${orgId}`,
      headers: { "session-token": sessionToken },
      data: [valuesToSend],
    };

    try {
      const response = await axiosInstance.request(options);
      const findingId = response.data[0];

      if (openedModal.data.id) {
        const linkOptions = {
          method: "POST",
          url: `/nocodb/link_findings?org_id=${orgId}&link_target=AuditTask&target_id=${openedModal.data.id}`,
          headers: { "session-token": sessionToken },
          data: [findingId],
        };

        await axiosInstance.request(linkOptions);
      }
    } catch (error) {
      console.error("Error adding finding:", error);
    } finally {
      await dispatch(
        getOrganizationData(
          orgId,
          sessionToken,
          navigate,
          false,
          true,
          false,
          false,
        ),
      );

      setLoading(false);
      dispatch(hideModal());
      actions.setSubmitting(false);
    }
  };

  return (
    <Modal
      name="new_finding_modal"
      title="New Finding"
      onClose={handleCloseModal}
      width="55vw"
    >
      <Formik
        initialValues={{
          description: "",
          date: "",
          reference: "",
          attachments: [],
          risk: "",
          status: "Done",
          chapter: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          setLoading(true);
          handleSubmit(values, actions);
        }}
      >
        {({ values, setFieldValue }) => {
          // Extract the chapter id from values.chapter
          const chapterId = values.chapter.split(" ")[0]; // Get the first part of the string before the first space

          // Filter riskOptions based on the extracted chapterId
          const filteredRiskOptions = riskOptions.filter((risk) =>
            risk.reference.startsWith(chapterId),
          );

          return (
            <Form className={styles.content}>
              <div className={styles.description}>
                <label htmlFor="description">Description</label>
                <Field name="description" component={Textarea} />
              </div>
              <div className={styles.row}>
                <div className={styles.date}>
                  <label htmlFor="date">Date</label>
                  <Field
                    name="date"
                    component={DatePickerField}
                    validateOnBlur={true}
                    validateOnChange={false}
                  />
                </div>
                <div className={styles.reference}>
                  <label htmlFor="reference">Reference</label>
                  <Field name="reference" component={Input} />
                </div>
              </div>
              <div>
                <FormikCheckbox name="status" label="Needs follow-up?" />
              </div>
              <div className={styles.attachment}>
                <label htmlFor="attachments">Attachments</label>
                <Field name="attachments" component={FormikUpload} greenIcon />
              </div>
              <div className={styles.row}>
                <div className={styles.chapter}>
                  <label htmlFor="chapter">Associated Chapter</label>
                  <Field
                    name="chapter"
                    component={Select}
                    options={chapterOptions}
                    text="Select chapter"
                    onClick={() => {
                      setFieldValue("risk", "");
                    }}
                  />
                </div>
                <div className={styles.risk}>
                  <label htmlFor="risk">Associated Risk</label>
                  <Field
                    name="risk"
                    component={Select}
                    options={filteredRiskOptions}
                    text="Select risk"
                    disabled={values.chapter === ""}
                  />
                </div>
              </div>
              <Button
                text={loading ? "Adding..." : "Add New Finding"}
                type="submit"
                className={styles.submitButton}
                disabled={loading}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default NewFindingModal;
