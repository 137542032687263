import { createSlice } from "@reduxjs/toolkit";

export const userStore = createSlice({
  name: "user",
  initialState: {
    email: "",
    user: {},
    organizations: {},
    modules: {},
  },
  reducers: {
    setUserData: (state, action) => {
      state.email = action.payload.email;
      state.user = action.payload.data.user;
      state.organizations = action.payload.data.organizations;
      state.modules = action.payload.data.modules;
    },
  },
});

export const { setUserData } = userStore.actions;

export default userStore.reducer;
