import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./HomePage.module.scss";
import ListIcon from "../../icons/circle-list.svg";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import WorkInProgressIllustration from "../../icons/work-in-progress-illustration.svg";
import Button from "../../components/Button/Button";
import { setLoadingContext } from "../../redux/loaderStore";
import { PAGE_LOADER } from "../../constants/loader";
import Card from "../../components/Card/Card";
import _ from "lodash";

const HomePage = () => {
  const { orgId } = useParams();
  const dispatch = useDispatch();
  const modules = useSelector((state) => state.user.modules);
  const [organizationModules, setOrganizationModules] = useState(null);
  const { findingsData, improvementsData, auditsData, data, risksData } =
    useSelector((state) => state.organization);

  let navigate = useNavigate();
  console.log(data, "data");

  localStorage.setItem("orgId", orgId);

  useEffect(() => {
    dispatch(setLoadingContext({ context: PAGE_LOADER, isLoading: true }));

    if (modules && modules[orgId]) {
      setOrganizationModules(modules[orgId]);
      dispatch(setLoadingContext({ context: PAGE_LOADER, isLoading: false }));
    }
  }, [modules, orgId]);

  const homeModule = organizationModules?.find(
    (module) => module.title === "Home",
  );

  const filterModulesByCategory = (category) => {
    return organizationModules
      ? organizationModules.filter(
          (module) => module.category?.toLowerCase() === category.toLowerCase(),
        )
      : [];
  };

  console.log(modules, "moduli");

  return (
    <MainLayout>
      <div className={styles.homePageWrapper}>
        {homeModule && (
          <div className={styles.cardWrapper}>
            <Card
              category="define"
              modules={filterModulesByCategory("Define")}
            />
            <Card
              category="measure"
              modules={filterModulesByCategory("Measure")}
            />
            <Card
              category="analyse"
              modules={filterModulesByCategory("Analyse")}
            />
            <Card
              category="improve"
              modules={filterModulesByCategory("Improve")}
            />
            {/*{modules.length > 0 && (*/}
            <Card
              category="control"
              modules={filterModulesByCategory("Control")}
            />
            {/*)}*/}
          </div>
        )}
        {homeModule ? (
          homeModule.params ? (
            <div
              dangerouslySetInnerHTML={{ __html: homeModule.params.content }}
            />
          ) : (
            <div className={styles.customHtmlContainer}>
              <div className={styles.mainTitle}>
                Explanation of Customs Compliance Management
              </div>
              <div className={styles.introduction}>
                <div className={styles.text}>
                  The QMS - consists of five elements, each fulfilling its own
                  part.
                  <br />
                  Together the elements form a PDCA (Plan- Do-Check-Act) cycle
                  which lays the foundation for customs compliance management.
                  <br />
                  The compliance system is based on the philosophy of the lean
                  and six sigma method, which strives to eliminate waste and
                  reduce variations.
                </div>
              </div>
              <div className={styles.secondaryTitle}>
                Introduction to the Quality Management System QMS
              </div>
              <div className={styles.text}>
                We take quality and compliance seriously. Our Quality Management
                System (QMS) is an essential part of our operation, and we
                believe it is necessary to meet the demands of authorities and
                clients. The QMS is a framework that sets out the policies,
                processes, and procedures needed to achieve our quality
                objectives and mitigate our compliance risks. Our QMS is
                designed to meet international standards such as CTPAT, AEO, and
                ISO 9001:2015.
                <br />
                <br />
                We believe that a quality mindset is essential, and we carry a
                message that focuses on awareness and empowerment at all levels
                of the organization. Our lean Six Sigma-based method focuses on
                activating the right continuous improvement mindset, adding
                value to the customer's business and process ownership.
              </div>
              <div className={styles.secondaryTitle}>
                Traditional Quality Approach:
              </div>
              <div className={styles.listWrapper}>
                <div className={styles.listItem}>
                  <img src={ListIcon} className={styles.icon} alt="Circle" />
                  <span>
                    Characterized by a hierarchical pyramid, this approach
                    operates on a top-down decision-making process. While it
                    ensures compliance, it may do so at the expense of agility
                    and innovation.
                  </span>
                </div>
                <div className={styles.listItem}>
                  <img src={ListIcon} className={styles.icon} alt="Circle" />
                  <span>
                    The presence of bureaucracy indicates a reliance on formal
                    procedures and protocols, which can provide stability and
                    predictability but may also lead to rigidity.
                  </span>
                </div>
                <div className={styles.listItem}>
                  <img src={ListIcon} className={styles.icon} alt="Circle" />
                  <span>
                    Departmental silos, a common feature in this model, can
                    result in compartmentalized functions that might stifle
                    cross-functional communication and collaboration.
                  </span>
                </div>
              </div>
              <div className={styles.secondaryTitle}>
                Quality Management System:
              </div>
              <div className={styles.listWrapper}>
                <div className={styles.listItem}>
                  <img src={ListIcon} className={styles.icon} alt="Circle" />
                  <span>
                    Depicted as an interconnected, organic network, the modern
                    QMS embodies an integrated approach where processes and
                    departments are not isolated but work{" "}
                    <a
                      href="http://cohesively.it/"
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                      className={styles.link}
                    >
                      cohesively.
                    </a>
                  </span>
                </div>
                <div className={styles.listItem}>
                  <img src={ListIcon} className={styles.icon} alt="Circle" />
                  <span>
                    It demonstrates a process-oriented system with dynamic
                    interactions, signifying a departure from rigid hierarchies
                    to a more flexible structure that encourages continuous
                    feedback and collaborative efforts.
                  </span>
                </div>
                <div className={styles.listItem}>
                  <img src={ListIcon} className={styles.icon} alt="Circle" />
                  <span>
                    Transparency within QMS organizations is emphasized,
                    allowing for greater visibility of processes and fostering a
                    culture that values continuous improvement.
                  </span>
                </div>
                <div className={styles.listItem}>
                  <img src={ListIcon} className={styles.icon} alt="Circle" />
                  <span>
                    The QMS structure is adaptable, facilitating quick responses
                    to change and innovation, which is essential for meeting
                    evolving customer expectations and market conditions.
                  </span>
                </div>
              </div>
              <div className={styles.conclusion}>
                <div className={styles.text}>
                  While the traditional quality approach meets ISO 9001
                  standards, focusing on structure and stability, the QMS
                  approach leverages flexibility and adaptability. It applies
                  the principles of ISO 9001 to foster an environment that not
                  only ensures compliance but also enhances business value,
                  efficiency, and customer satisfaction. The modern QMS model
                  facilitates a more fluid integration of quality management,
                  promoting a proactive stance towards continuous improvement
                  and Operational excellence.
                </div>
              </div>
            </div>
          )
        ) : (
          <div className={styles.workInProgressWrapper}>
            <img
              src={WorkInProgressIllustration}
              alt="Developer working"
              className={styles.illustration}
            />
            <span>This organization is not yet configured.</span>
            <Button
              text="Go back"
              type="button"
              onClick={() => navigate("/organizations")}
              className={styles.button}
            />
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default HomePage;
