import { createSlice } from "@reduxjs/toolkit";

export const authStore = createSlice({
  name: "auth",
  initialState: {
    loggedIn: "pending",
  },
  reducers: {
    updateLoggedIn: (state, action) => {
      state.loggedIn = action.payload;
    },
  },
});

export const { updateLoggedIn } = authStore.actions;

export default authStore.reducer;
