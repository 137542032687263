import React from "react";
import styles from "./FloatingBar.module.scss";
import Button from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../redux/modalStore";
import SecondaryButton from "../Button/SecondaryButton";
import UserIcon from "../../icons/person-green.svg";
import { toggleMeMode } from "../../redux/filterStore";
import { useParams } from "react-router-dom";

const FloatingBar = () => {
  const dispatch = useDispatch();
  const { orgId } = useParams();

  const meModeActive = useSelector((state) => state.filters.meModeActive);
  const modules = useSelector((state) => state.user.modules[orgId]);

  const handleOpenNewFindingModal = () => {
    dispatch(
      showModal({
        name: "new_finding_modal",
      }),
    );
  };

  const handleToggleMeMode = () => {
    dispatch(toggleMeMode());
  };

  // If there are no modules present, don't render floating bar
  if (!modules || modules.length === 0) {
    return null;
  }

  return (
    <div className={styles.floatingBar}>
      <SecondaryButton
        className={styles.secondaryButton}
        text="Add New Finding"
        onClick={handleOpenNewFindingModal}
      />
      <Button
        className={styles.primaryButton}
        icon={UserIcon}
        text={meModeActive ? "Exit Me Mode" : "Go To Me Mode"}
        onClick={handleToggleMeMode}
      />
    </div>
  );
};

export default FloatingBar;
