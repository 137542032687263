import React, { useState, useRef } from "react";
import styles from "./Upload.module.scss";
import AddFileIcon from "../../icons/add-folder.svg";
import UploadIcon from "../../icons/upload.svg";
import RemoveFileIcon from "../../icons/cross-dark.svg";

const Upload = ({ questionId, onChange }) => {
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    onChange(selectedFile);
  };

  const handleRemoveFile = (e) => {
    e.stopPropagation(); // Prevent event propagation to not open add file automatically
    setFile(null);
    onChange(null);
    fileInputRef.current.value = ""; // Reset the input value
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.uploadWrapper}>
        <div className={styles.leftSide}>
          <label
            htmlFor={`file-upload-${questionId}`}
            className={styles.upload}
          >
            {!file && <img src={AddFileIcon} alt="Upload" />}
            {file ? (
              <div className={styles.selectedFile}>
                <div className={styles.text}>Selected file: {file.name}</div>
              </div>
            ) : (
              <div className={styles.text}>Upload file</div>
            )}
          </label>
          {file && (
            <div onClick={handleRemoveFile} className={styles.removeButton}>
              <img src={RemoveFileIcon} alt="Remove" />
            </div>
          )}
        </div>
        <input
          type="file"
          onChange={handleFileChange}
          className={styles.fileInput}
          id={`file-upload-${questionId}`}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
      </div>
    </div>
  );
};

export default Upload;
