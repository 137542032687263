import { createSlice } from "@reduxjs/toolkit";

export const auditsStore = createSlice({
  name: "audits",
  initialState: {
    auditsData: [],
  },
  reducers: {
    setAuditsData: (state, action) => {
      state.auditsData = Array.isArray(action.payload) ? action.payload : [];
    },
  },
});

export const { setAuditsData } = auditsStore.actions;

export default auditsStore.reducer;
