import React, { useState } from "react";
import styles from "./ImprovementProjects.module.scss";
import Table from "../../components/Table/Table";
import { useLocation, useParams } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import classNames from "classnames";
import Input from "../../components/Input/Input";
import SearchIcon from "../../icons/search.svg";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import { formatDate } from "../../utils/helpers";
import { showModal } from "../../redux/modalStore";
import { useDispatch, useSelector } from "react-redux";
import ImprovementProjectsModal from "../../modals/ImprovementProjectsModal";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Separator from "../../components/Separator/Separator";
import { setLinkedData } from "../../redux/organizationStore";
import DateRangeFilter from "../../components/DateRangeFilter/DateFIlterRange";

const ImprovementProjects = () => {
  const { orgId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedResponsibles, setSelectedResponsibles] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);

  const [selectedImprovementProject, setSelectedImprovementProject] =
    useState(null);

  const modules = useSelector((state) => state.user.modules[orgId]);
  const { meModeActive } = useSelector((state) => state.filters);
  const currentUser = useSelector((state) => state.user.email);
  const { improvementsData, data } = useSelector((state) => state.organization);

  const improvementProjectsData = Object.values(improvementsData || {});

  // Extracting path from URL
  const lastIndex = location.pathname.lastIndexOf("/");
  const modifiedPath = location.pathname.substring(lastIndex);

  // Find the module object corresponding to the current path
  const currentModule = modules?.find((mod) => mod.path === modifiedPath);

  // Extracting unique responsibles using flatMap for consistent handling
  const uniqueResponsibles = improvementProjectsData
    .flatMap((project) =>
      Array.isArray(project.responsible)
        ? project.responsible
        : [project.responsible],
    )
    .filter(Boolean)
    .filter((value, index, self) => self.indexOf(value) === index)
    .map((responsible) => ({ id: responsible, label: responsible }));

  // Extracting unique statuses using the same approach as in findings
  const uniqueStatuses = improvementProjectsData
    .flatMap((project) =>
      Array.isArray(project.status) ? project.status : [project.status],
    )
    .filter(Boolean)
    .filter((value, index, self) => self.indexOf(value) === index)
    .map((status) => ({ id: status, label: status }));

  const handleResponsibleFilterChange = (newSelectedValues) => {
    setSelectedResponsibles(newSelectedValues);
  };

  const handleStatusFilterChange = (newSelectedValues) => {
    setSelectedStatuses(newSelectedValues);
  };

  const handleDateRangeFilterChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const filteredData = improvementProjectsData.filter((item) => {
    const { responsible, status, date: itemDate } = item;

    const dueDate = new Date(itemDate);
    const formattedStartDate = dateRange[0];
    const formattedEndDate = dateRange[1];

    // Convert search query to lowercase for consistent comparison
    const searchQueryLower = searchQuery.toLowerCase();

    // Responsible match logic handling both arrays and single values
    const responsibleMatch =
      selectedResponsibles.length === 0 ||
      (Array.isArray(responsible)
        ? responsible.some((r) => selectedResponsibles.includes(r))
        : selectedResponsibles.includes(responsible));

    // Status match using labels directly
    const statusMatch =
      selectedStatuses.length === 0 || selectedStatuses.includes(status);

    // Date range match
    const dateMatch =
      (!formattedStartDate && !formattedEndDate) ||
      (dueDate >= formattedStartDate && dueDate <= formattedEndDate);

    // Search query match
    const searchMatch =
      !searchQueryLower ||
      item.key?.toLowerCase().includes(searchQueryLower) ||
      item.name?.toLowerCase().includes(searchQueryLower) ||
      item.description?.toLowerCase().includes(searchQueryLower) ||
      (Array.isArray(responsible)
        ? responsible.some((r) => r.toLowerCase().includes(searchQueryLower))
        : responsible?.toLowerCase().includes(searchQueryLower)) ||
      status?.toString().toLowerCase().includes(searchQueryLower);

    // Me mode filter: only show projects where the current user is responsible
    const meModeMatch =
      !meModeActive ||
      (Array.isArray(responsible)
        ? responsible.includes(currentUser)
        : responsible === currentUser);

    return [
      responsibleMatch,
      statusMatch,
      dateMatch,
      searchMatch,
      meModeMatch,
    ].every(Boolean);
  });

  const getLinkedData = (improvement) => {
    const { linked_findings = [] } = improvement;

    const findings = linked_findings
      .map((key) => {
        return data.findings?.[key];
      })
      .filter(Boolean); // Filter out any undefined values

    return { linked_findings: findings };
  };

  const handleRowClick = (row) => {
    const linkedData = getLinkedData(row.original);
    dispatch(setLinkedData(linkedData));
    setSelectedImprovementProject(row.original);
    dispatch(
      showModal({
        name: "improvement_projects_modal",
        data: row.original,
      }),
    );
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return (
      <>
        {text.substring(0, maxLength)}
        <span className={styles.readMore}>... read more</span>
      </>
    );
  };

  const columns = [
    {
      accessorKey: "key",
      header: "Key",
      size: 120,
      cell: (props) => <div>{props.getValue()}</div>,
    },
    {
      accessorKey: "name",
      header: "Name",
      size: 200,
      cell: (props) => <div>{props.getValue()}</div>,
    },
    {
      accessorKey: "description",
      header: "Description",
      size: 350,
      cell: (props) => <div>{truncateText(props.getValue(), 80)}</div>,
    },
    {
      accessorKey: "date",
      header: "Date",
      size: 130,
      cell: (props) => (
        <div>
          {props.getValue() ? formatDate(props.getValue()) : "Not defined"}
        </div>
      ),
    },
    {
      accessorKey: "responsible",
      header: "Responsible",
      size: 170,
      cell: (props) => {
        return props.row.original.responsible ? (
          <div>{props.row.original.responsible}</div>
        ) : (
          <div>Not defined</div>
        );
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 120,
      cell: (props) => {
        const status = props.getValue();
        let statusClassName = "";

        switch (status) {
          case "Open":
            statusClassName = styles.openStatus;
            break;
          case "In Progress":
            statusClassName = styles.inProgressStatus;
            break;
          case "In Review":
            statusClassName = styles.inReviewStatus;
            break;
          case "Done":
            statusClassName = styles.resolvedStatus;
            break;
          default:
            break;
        }

        return (
          <div className={classNames(styles.statusCell, statusClassName)}>
            {status}
          </div>
        );
      },
    },
  ];

  return (
    <MainLayout>
      <ImprovementProjectsModal
        improvementProjectsData={selectedImprovementProject}
      />
      <div className={styles.column}>
        <Breadcrumbs data={currentModule} />
        <Separator />
        <div className={styles.improvementProjectsContainer}>
          <div className={styles.rowWrapper}>
            <div className={styles.filtersWrapper}>
              <MultiSelect
                label={
                  selectedResponsibles.length > 0
                    ? selectedResponsibles.join(", ")
                    : "Responsible"
                }
                options={uniqueResponsibles}
                onChangeSelectedValues={handleResponsibleFilterChange}
                selectedValues={selectedResponsibles}
                disabled={!improvementProjectsData.length}
              />
              <MultiSelect
                label={
                  selectedStatuses.length > 0
                    ? selectedStatuses.join(", ")
                    : "All statuses"
                }
                options={uniqueStatuses}
                selectedValues={selectedStatuses}
                onChangeSelectedValues={handleStatusFilterChange}
                disabled={!improvementProjectsData.length}
              />
              <DateRangeFilter
                handleDateRangeChange={handleDateRangeFilterChange}
                dateRange={dateRange}
                isClearable
              />
            </div>
            <Input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={styles.input}
              icon={SearchIcon}
            />
          </div>
          <Table
            columns={columns}
            data={filteredData ? filteredData : improvementProjectsData}
            onRowClick={handleRowClick}
            rowHover
          />
          {!filteredData?.length && (
            <div className={styles.noData}>
              Sorry, there is no data to display.
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default ImprovementProjects;
