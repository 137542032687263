import React, { useEffect, useState } from "react";
import styles from "./Table.module.scss";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";

const Table = ({
  columns,
  data,
  onRowClick,
  onSelectedRowsChange = () => {},
  centered,
  rowHover,
}) => {
  const [rowSelection, setRowSelection] = useState({});

  const handleRowClick = (row) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  const table = useReactTable({
    data: data || [], // Ensure data is always an array
    columns: columns || [], // Ensure columns is always an array
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: !!onSelectedRowsChange,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
  });

  useEffect(() => {
    // Pass selected rows data to the parent component whenever selection changes
    const selectedRows = table
      .getSelectedRowModel()
      .rows.map((row) => row.original);
    onSelectedRowsChange(selectedRows);
  }, [rowSelection]);

  return (
    <div
      className={styles.tableContainer}
      style={{ width: table.getCenterTotalSize() }}
    >
      {table.getHeaderGroups().map((headerGroup) => (
        <div className={styles.tr} key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <div
              className={styles.th}
              style={{ width: header.getSize() }}
              key={header.id}
            >
              {header?.column?.columnDef?.header}
              <div
                onMouseDown={header.getResizeHandler()}
                className={classNames(
                  styles.resizer,
                  header.column.getIsResizing() && styles.isResizing,
                )}
              />
            </div>
          ))}
        </div>
      ))}
      {table.getRowModel()?.rows?.map((row) => (
        <div
          className={classNames(styles.tr, rowHover && styles.hover)}
          key={row.id}
          onClick={() => handleRowClick(row)}
        >
          {row.getVisibleCells().map((cell) => (
            <div
              className={classNames(
                styles.td,
                centered &&
                  cell.column.columnDef.header === "Status" &&
                  styles.centeredData,
                onRowClick && styles.pointer,
              )}
              style={{ width: cell.column.getSize() }}
              key={cell.id}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Table;
