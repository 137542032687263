import { createSlice } from "@reduxjs/toolkit";

export const modalConfluenceStore = createSlice({
  name: "modalConfluence",
  initialState: {
    modalConfluencePageData: {},
  },
  reducers: {
    setModalConfluencePage: (state, action) => {
      state.modalConfluencePageData = action.payload;
    },
    resetModalConfluenceStore: (state, action) => {
      state.modalConfluencePageData = {};
    },
  },
});

export const { setModalConfluencePage, resetModalConfluenceStore } =
  modalConfluenceStore.actions;

export default modalConfluenceStore.reducer;
