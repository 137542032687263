import { createSlice } from "@reduxjs/toolkit";

export const loaderStore = createSlice({
  name: "loader",
  initialState: {
    loadingContexts: {},
  },
  reducers: {
    setLoadingContext: (state, action) => {
      const { context, isLoading } = action.payload;
      state.loadingContexts[context] = isLoading;
    },
  },
});

export const { setLoadingContext } = loaderStore.actions;

export default loaderStore.reducer;
