import React, { useEffect } from "react";
import { Field, ErrorMessage } from "formik";
import DateFilter from "../DateFilter/DateFilter";

const DatePickerField = ({ field, form, ...props }) => {
  const handleDateChange = (date) => {
    form.setFieldValue(field.name, date, false);
  };

  useEffect(() => {
    // Set touched and validate only if submitCount > 0
    if (form.submitCount > 0 && !form.touched[field.name]) {
      form.setFieldTouched(field.name, true);
      form.validateField(field.name);
    }
  }, [form.submitCount]);

  return (
    <div>
      <DateFilter
        startDate={field.value || new Date()}
        handleDateFilterChange={handleDateChange}
        maxDate={props.maxDate}
        isClearable={props.isClearable}
      />
      {form.submitCount > 0 && form.errors[field.name] && (
        <div className="error">{form.errors[field.name]}</div>
      )}
    </div>
  );
};

export default DatePickerField;
