export function hasRealContent(htmlString) {
  // Check if the input is null or undefined
  if (!htmlString) {
    return false;
  }

  // Parse the HTML string into a DOM object
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Helper function to check if an element has real content
  function elementHasContent(element) {
    // Ignore empty text nodes and specific empty elements like <br>
    if (element.nodeType === Node.TEXT_NODE) {
      return element.nodeValue.trim().length > 0;
    }
    if (element.nodeType === Node.ELEMENT_NODE) {
      if (element.tagName === "BR") return false;
      // Check for non-empty text content or nested elements with content
      if (element.textContent.trim().length > 0) return true;
      // Recursively check child nodes
      for (let child of element.childNodes) {
        if (elementHasContent(child)) return true;
      }
    }
    return false;
  }

  // Check the body of the parsed HTML document
  return elementHasContent(doc.body);
}

// Format date to exclude hours
export function formatDate(dateString) {
  // Try to parse the date string into a Date object
  const date = new Date(dateString);

  // Check if the parsed date is valid
  if (!isNaN(date.getTime())) {
    //getTime() method to get the number representation of the date
    // Extract day, month, and year
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    // Return formatted date string without hours
    return `${day} ${month} ${year}`;
  } else {
    // If the date string is not a valid date, return the original string
    return dateString;
  }
}

// Helper function to truncate text to a specified word limit and add ellipsis
export const getTruncatedContent = (text, wordLimit) => {
  if (typeof text !== "string") {
    return "";
  }

  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return text;
};

// Helper function to find category by using title
export const findCategory = (title, data) => {
  const item = data?.find((obj) => obj.title === title);
  return item ? item.category : "";
};
