import React, { useEffect, useState } from "react";
import Modal from "../components/Modal/Modal";
import styles from "./FindingsModal.module.scss";
import classNames from "classnames";
import Loader from "../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import Separator from "../components/Separator/Separator";
import { formatDate } from "../utils/helpers";
import SecondaryButton from "../components/Button/SecondaryButton";
import { useNavigate, useParams } from "react-router-dom";
import { hideModal } from "../redux/modalStore";
import Table from "../components/Table/Table";
import EditIcon from "../icons/edit.svg";
import Button from "../components/Button/Button";
import { Field, Form, Formik } from "formik";
import Input from "../components/Input/Input";
import Select from "../components/Select/Select";
import Textarea from "../components/Textarea/Textarea";
import axiosInstance from "../utils/utils";
import { getOrganizationData } from "../actions/organization";
import Pill from "../components/Pill/Pill";
import * as Yup from "yup";
import ModalSideBar from "../components/ModalSideBar/ModalSideBar";

const FindingsModal = ({ findingData, isLoading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const sessionToken = localStorage.getItem("session_token");

  const { linkedData, risksData } = useSelector((state) => state.organization);
  const openedModal = useSelector((state) => state.modal.openedModal);

  const [editField, setEditField] = useState(null);
  const [editableData, setEditableData] = useState(findingData || {});
  const [unsavedChanges, setUnsavedChanges] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [updates, setUpdates] = useState(openedModal?.data?.updates || []);

  // Function to toggle sidebar visibility
  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    setEditableData(findingData || {});
    setUnsavedChanges({});
    setEditField(null);
    setLoading(false);
    setUpdates(openedModal?.data?.updates || []);
  }, [findingData]);

  const handleOnClose = () => {
    setEditableData(findingData || {});
    setEditField(null);
    dispatch(hideModal());
  };

  const handleNavigationClick = (path) => {
    navigate(`/${orgId}/${path}`);
    handleOnClose();
  };

  const handleFieldChange = (field, value) => {
    const newValue = value === "" ? (field === "responsible" ? "" : []) : value;
    setUnsavedChanges((prevChanges) => ({
      ...prevChanges,
      [field]: newValue,
    }));
    setEditableData((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleSave = async (values) => {
    const sanitizeData = (data) => {
      return Object.keys(data).reduce((acc, key) => {
        if (Array.isArray(data[key])) {
          // If the array is empty or contains only empty strings, send it as an empty array
          acc[key] =
            data[key].filter((item) =>
              typeof item === "string" ? item.trim() !== "" : item,
            ) || [];
        } else if (typeof data[key] === "string") {
          // Allow empty strings to be sent
          acc[key] = data[key].trim();
        } else {
          // For other types, retain as is or set to null if explicitly undefined
          acc[key] = data[key] ?? "";
        }
        return acc;
      }, {});
    };

    const sanitizedValues = sanitizeData(values);
    const sanitizedOriginalData = sanitizeData(findingData);

    const changedFields = {};
    for (const key in sanitizedValues) {
      if (key === "responsible") {
        if (sanitizedValues[key] !== sanitizedOriginalData[key]) {
          changedFields[key] = sanitizedValues[key];
        }
      } else if (
        typeof sanitizedValues[key] === "object" &&
        sanitizedValues[key] !== null &&
        !Array.isArray(sanitizedValues[key])
      ) {
        // For objects, compare their JSON string representations
        if (
          JSON.stringify(sanitizedValues[key]) !==
          JSON.stringify(sanitizedOriginalData[key])
        ) {
          changedFields[key] = sanitizedValues[key];
        }
      } else if (Array.isArray(sanitizedValues[key])) {
        // Ensure both values are arrays before comparing with sort
        const sortedSanitizedValues = [...sanitizedValues[key]].sort(); // Create a shallow copy before sorting
        const sortedOriginalData = Array.isArray(sanitizedOriginalData[key])
          ? [...sanitizedOriginalData[key]].sort() // Create a shallow copy before sorting
          : [];
        if (
          JSON.stringify(sortedSanitizedValues) !==
          JSON.stringify(sortedOriginalData)
        ) {
          changedFields[key] = sanitizedValues[key];
        }
      } else {
        // For primitive values (string, number, etc.)
        if (sanitizedValues[key] !== sanitizedOriginalData[key]) {
          changedFields[key] = sanitizedValues[key];
        }
      }
    }

    if (Object.keys(changedFields).length === 0) {
      return;
    }

    const payload = {
      id: findingData?.id,
      ...changedFields,
    };

    setLoading(true);

    try {
      const response = await axiosInstance.patch(
        `/nocodb/findings?org_id=${orgId}`,
        [payload],
        {
          headers: {
            "Content-Type": "application/json",
            "session-token": sessionToken,
          },
        },
      );

      // Update the local state with the saved changes
      setEditableData((prevData) => ({
        ...prevData,
        ...changedFields,
      }));

      setUnsavedChanges({});
      setEditField(null);

      // Fetch the updated organization data
      await dispatch(
        getOrganizationData(
          orgId,
          sessionToken,
          navigate,
          false,
          true,
          true,
          true,
        ),
      );
    } catch (error) {
      console.error("There was a problem with the save operation:", error);
    } finally {
      setLoading(false);
      handleOnClose(); // Close the modal after saving and fetching data
    }
  };

  const safeLinkedData = Array.isArray(linkedData) ? linkedData : [];

  const columns = [
    {
      accessorKey: "key",
      header: "Key",
      size: 100,
      cell: (props) => <div>{props.getValue()}</div>,
    },
    {
      accessorKey: "name",
      header: "Name",
      size: 150,
      cell: (props) => <div>{props.getValue()}</div>,
    },
    {
      accessorKey: "description",
      header: "Description",
      size: 350,
      cell: (props) => <div>{truncateText(props.getValue(), 80)}</div>,
    },
    {
      accessorKey: "responsible",
      header: "Responsible",
      size: 200,
      cell: (props) => {
        return props.row.original.responsible ? (
          <div>{props.row.original.responsible}</div>
        ) : (
          <div>Not defined</div>
        );
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 120,
      cell: (props) => {
        const status = props.getValue();
        let statusClassName = "";

        switch (status) {
          case "Open":
            statusClassName = styles.openStatus;
            break;
          case "In Progress":
            statusClassName = styles.inProgressStatus;
            break;
          case "Closed":
            statusClassName = styles.closedStatus;
            break;
          case "Done":
            statusClassName = styles.resolvedStatus;
            break;
          default:
            break;
        }

        return (
          <div className={classNames(styles.statusCell, statusClassName)}>
            {status}
          </div>
        );
      },
    },
  ];

  const truncateText = (text, maxLength) => {
    if (text?.length <= maxLength) {
      return text;
    }
    return (
      <>
        {text?.substring(0, maxLength)}
        <span className={styles.readMore}>... read more</span>
      </>
    );
  };

  let riskColor;
  switch (findingData?.risk?.level) {
    case "high":
      riskColor = styles.highLevel;
      break;
    case "medium":
      riskColor = styles.mediumLevel;
      break;
    case "low":
      riskColor = styles.lowLevel;
      break;
    default:
      riskColor = "";
  }

  const statusOptions = [
    {
      id: 1,
      label: "Open",
    },
    {
      id: 2,
      label: "In Progress",
    },
    {
      id: 3,
      label: "In Review",
    },
    {
      id: 4,
      label: "Done",
    },
  ];

  // Helper function to get the label from statusOptions based on the value
  const getStatusLabel = (statusValue) => {
    const statusOption = statusOptions.find(
      (option) => option.label === statusValue,
    );
    return statusOption ? statusOption.label : statusValue;
  };

  const renderEmails = (emails, editField, fieldName, setEditField) => {
    if (editField === fieldName) {
      return (
        <Field
          name={fieldName}
          component={Pill}
          placeholder="Enter emails"
          limit={5}
        />
      );
    }

    if (Array.isArray(emails)) {
      return emails.map((email) => (
        <div key={email} className={styles.items}>
          {email}
        </div>
      ));
    } else if (typeof emails === "string") {
      return <div className={styles.items}>{emails}</div>;
    }

    return null;
  };

  const validationSchema = Yup.object().shape({
    responsible: Yup.string().matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email address",
    ),
  });

  return (
    <Modal
      key={findingData?.id}
      name="findings_modal"
      title={findingData?.key}
      width="fit-content"
      onClose={handleOnClose}
      minWidth="50vw"
      maxWidth="70vw"
    >
      <div className={styles.modalContentContainer}>
        <div className={styles.modalContent}>
          {isLoading ? (
            <Loader text="Loading, thank you for your patience." />
          ) : (
            <>
              <Formik
                initialValues={{
                  ...editableData,
                  riskLabel: editableData.risk
                    ? `${editableData.risk.risk_id} - ${editableData.risk.description}`
                    : "",
                }}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }, field) => {
                  // Find the risk number based on the selected label
                  const selectedRisk = Object.values(risksData).find(
                    (risk) =>
                      `${risk.risk_id} - ${risk.description}` ===
                      values.riskLabel,
                  );

                  // Modify the values object to only include the risk_num as a string
                  const modifiedValues = {
                    ...values,
                    risk: selectedRisk ? String(selectedRisk.risk_num) : "", // Store risk_num as a string
                  };

                  // Remove the riskLabel before sending to handleSave
                  delete modifiedValues.riskLabel;

                  handleSave(modifiedValues); // Pass the modified values to handleSave
                }}
              >
                {({ values, setFieldValue, handleSubmit }) => {
                  return (
                    <Form className={styles.content}>
                      <div className={styles.firstRow}>
                        <div className={styles.row}>
                          <div className={styles.title}>Reporter:</div>
                          <div className={styles.bold}>
                            {findingData?.reporter}
                          </div>
                        </div>
                        <Button
                          text="See Updates"
                          onClick={toggleSidebar}
                          className={styles.toggleSidebarButton}
                          type="Button"
                        />
                      </div>
                      <div className={styles.row}>
                        <div className={styles.title}>Date:</div>
                        <div className={styles.bold}>
                          {formatDate(findingData?.date)}
                        </div>
                      </div>
                      <div className={styles.row}>
                        <div className={styles.title}>Status:</div>
                        <div className={styles.statusContainer}>
                          {editField === "status" ? (
                            <Field
                              name="status"
                              component={Select}
                              options={statusOptions}
                              value={values.status || editableData?.status}
                              onChange={(e) => {
                                const selectedOption = e.target.value;
                                setFieldValue("status", selectedOption);
                                handleFieldChange("status", selectedOption);
                                setEditField(null);
                              }}
                            >
                              {getStatusLabel(
                                values.status || editableData?.status,
                              )}
                            </Field>
                          ) : (
                            <>
                              <div
                                className={classNames(
                                  styles.statusCell,
                                  values.status === "In Progress"
                                    ? styles.inProgressStatus
                                    : values.status === "In Review"
                                      ? styles.inReviewStatus
                                      : values.status === "Done"
                                        ? styles.resolvedStatus
                                        : styles.openStatus,
                                )}
                              >
                                {values.status || editableData?.status}
                              </div>
                              <img
                                src={EditIcon}
                                className={styles.editIcon}
                                onClick={() => setEditField("status")}
                                alt="Edit"
                              />
                            </>
                          )}
                        </div>
                      </div>

                      {/* Responsible */}
                      <div className={styles.row}>
                        <div className={styles.title}>Responsible:</div>
                        <div className={styles.bold}>
                          {editField === "responsible" ? (
                            <Field
                              name="responsible"
                              component={Input}
                              placeholder="Enter responsible email(s)"
                            />
                          ) : (
                            <div>
                              {Array.isArray(values.responsible)
                                ? values.responsible.join(", ")
                                : values.responsible}
                            </div>
                          )}
                        </div>
                        {editField !== "responsible" && (
                          <img
                            src={EditIcon}
                            className={styles.editIcon}
                            onClick={() => setEditField("responsible")}
                            alt="Edit"
                          />
                        )}
                      </div>

                      {/* Accountable */}
                      <div className={styles.row}>
                        <div className={styles.title}>Accountable:</div>
                        <div className={styles.bold}>
                          {renderEmails(
                            values.accountable || editableData.accountable,
                            editField,
                            "accountable",
                            setEditField,
                          )}
                        </div>
                        <img
                          src={EditIcon}
                          className={styles.editIcon}
                          onClick={() => setEditField("accountable")}
                          alt="Edit"
                        />
                      </div>

                      {/* Consulted */}
                      <div className={styles.row}>
                        <div className={styles.title}>Consulted:</div>
                        <div className={styles.bold}>
                          {renderEmails(
                            values.consulted || editableData.consulted,
                            editField,
                            "consulted",
                            setEditField,
                          )}
                        </div>
                        <img
                          src={EditIcon}
                          className={styles.editIcon}
                          onClick={() => setEditField("consulted")}
                          alt="Edit"
                        />
                      </div>

                      {/* Informed */}
                      <div className={styles.row}>
                        <div className={styles.title}>Informed:</div>
                        <div className={styles.bold}>
                          {renderEmails(
                            values.informed || editableData.informed,
                            editField,
                            "informed",
                            setEditField,
                          )}
                        </div>
                        <img
                          src={EditIcon}
                          className={styles.editIcon}
                          onClick={() => setEditField("informed")}
                          alt="Edit"
                        />
                      </div>

                      <div className={styles.title}>Description:</div>
                      {editField === "description" ? (
                        <Field name="description" component={Textarea} />
                      ) : (
                        <div className={styles.description}>
                          <div>
                            {values.description
                              ? values.description
                              : editableData?.description}
                          </div>
                          <img
                            src={EditIcon}
                            className={styles.editIcon}
                            onClick={() => setEditField("description")}
                            alt="Edit"
                          />
                        </div>
                      )}
                      {findingData?.risk && (
                        <>
                          <div className={styles.row}>
                            <div className={styles.title}>Risk:</div>
                            {editField === "risk" ? (
                              <>
                                <Field
                                  name="riskLabel"
                                  component={Select}
                                  className={styles.select}
                                  options={Object.values(risksData).map(
                                    (risk) => ({
                                      value: `${risk.risk_id} - ${risk.description}`,
                                      label: `${risk.risk_id} - ${risk.description}`,
                                    }),
                                  )}
                                  onChange={(e) => {
                                    setFieldValue("riskLabel", e.target.value); // Store the selected label
                                    setEditField(null);
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <div className={styles.bold}>
                                  {values.riskLabel ||
                                    editableData?.risk?.risk_id +
                                      " " +
                                      editableData?.risk?.description}
                                </div>
                                <img
                                  src={EditIcon}
                                  className={styles.editIcon}
                                  onClick={() => setEditField("risk")}
                                  alt="Edit"
                                />
                              </>
                            )}
                          </div>
                          <div className={styles.row}>
                            <div className={styles.title}>Risk Level:</div>
                            <div
                              className={classNames(
                                styles.riskLevel,
                                riskColor,
                              )}
                            >
                              {editableData?.risk?.level}
                            </div>
                          </div>
                        </>
                      )}
                      <Separator />
                      <div className={styles.row}>
                        <div className={styles.title}>Reference:</div>
                        {editField === "reference" ? (
                          <Field
                            name="reference"
                            component={Input}
                            className={styles.bold}
                          />
                        ) : (
                          <>
                            <div className={styles.bold}>
                              {values.reference || editableData?.reference}
                            </div>
                            <img
                              src={EditIcon}
                              className={styles.editIcon}
                              onClick={() => setEditField("reference")}
                              alt="Edit"
                            />
                          </>
                        )}
                      </div>
                      {linkedData?.linked_improvements?.length > 0 && (
                        <>
                          <div className={styles.title}>
                            Linked Improvements
                          </div>
                          <Table
                            columns={columns}
                            data={linkedData.linked_improvements}
                          />
                          <SecondaryButton
                            text="View all improvements..."
                            className={styles.secondaryButton}
                            onClick={() =>
                              handleNavigationClick("improvement-projects")
                            }
                          />
                        </>
                      )}
                      {linkedData?.linked_auditTasks?.length > 0 && (
                        <>
                          <Separator />
                          <div className={styles.title}>Linked Audit Tasks</div>
                          <Table
                            columns={columns}
                            data={linkedData?.linked_auditTasks}
                          />
                          <SecondaryButton
                            text="View all audit tasks..."
                            className={styles.secondaryButton}
                            onClick={() => handleNavigationClick("audits")}
                          />
                        </>
                      )}
                      {findingData?.attachments.length > 0 && (
                        <>
                          <div className={styles.title}>Attachments:</div>
                          <div className={styles.attachments}>
                            {findingData?.attachments.map((attachment) => (
                              <a
                                key={attachment}
                                href={attachment}
                                target="_blank"
                                rel="noopener noreferrer"
                                download
                                className={styles.attachmentItem}
                              >
                                {attachment.split("/").pop()}
                              </a>
                            ))}
                          </div>
                        </>
                      )}
                      {editField && (
                        <Button
                          text={loading ? "Saving..." : "Save Changes"}
                          type="submit"
                          className={styles.submitButton}
                          disabled={loading}
                        />
                      )}
                    </Form>
                  );
                }}
              </Formik>
              <ModalSideBar
                isOpen={isSidebarOpen}
                onClose={() => setSidebarOpen(false)}
                updates={updates}
                setUpdates={setUpdates}
                findingId={openedModal?.data?.id}
              />
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default FindingsModal;
