import React from "react";
import styles from "./AuthLayout.module.scss";
import BorderlyLogo from "../../icons/logo-white.svg";
import ErrorModal from "../../modals/ErrorModal";

const AuthLayout = ({ children }) => {
  return (
    <div className={styles.background}>
      <img src={BorderlyLogo} alt="Borderly Logo" className={styles.logo} />
      <div className={styles.box}>{children}</div>
      <ErrorModal />
    </div>
  );
};

export default AuthLayout;
