import { createSlice } from "@reduxjs/toolkit";

export const improvementsStore = createSlice({
  name: "improvements",
  initialState: {
    improvementProjectsData: [],
    improvementsIssue: {},
  },
  reducers: {
    setImprovementsProjectData: (state, action) => {
      state.improvementProjectsData = action.payload;
    },
    setImprovementsIssueData: (state, action) => {
      state.improvementsIssue = action.payload;
    },
  },
});

export const { setImprovementsProjectData, setImprovementsIssueData } =
  improvementsStore.actions;

export default improvementsStore.reducer;
