import { createSlice } from "@reduxjs/toolkit";

export const modalStore = createSlice({
  name: "modal",
  initialState: {
    openedModal: null,
  },
  reducers: {
    showModal: (state, action) => {
      state.openedModal = action.payload;
    },
    hideModal: (state, action) => {
      state.openedModal = null;
    },
  },
});

export const { showModal, hideModal } = modalStore.actions;

export default modalStore.reducer;
