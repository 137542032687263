import React, { useState } from "react";
import classNames from "classnames";
import styles from "./Select.module.scss";
import DownArrowIcon from "../../icons/arrow-down.svg";
import UpArrowIcon from "../../icons/arrow-up.svg";
import OutsideClickHandler from "react-outside-click-handler";

const Select = ({
  text,
  field,
  form,
  options,
  disabled,
  className,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (option) => {
    // Store the label in the Formik state instead of the id
    form.setFieldValue(field.name, option.label);
    setIsOpen(false);
  };

  // Find the selected option by matching the stored label
  const selectedOption = options.find((option) => option.label === field.value);

  const hasError = form.errors[field.name] && form.touched[field.name];

  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
      <div className={classNames(styles.selectWrapper, className)}>
        <div
          className={classNames(
            styles.select,
            disabled && styles.disabled,
            hasError && styles.error,
          )}
          onClick={() => !disabled && setIsOpen(!isOpen)}
        >
          {/* Display the label of the selected option or fallback to the placeholder text */}
          {selectedOption ? selectedOption.label : <span>{text}</span>}
          {isOpen ? (
            <img
              src={UpArrowIcon}
              alt="Up Arrow Icon"
              className={styles.icon}
            />
          ) : (
            <img
              src={DownArrowIcon}
              alt="Down Arrow Icon"
              className={styles.icon}
            />
          )}
        </div>
        {isOpen && (
          <div className={styles.options}>
            {options.map((option, index) => {
              return (
                <div
                  key={index}
                  className={classNames(
                    styles.option,
                    option.label === field.value && styles.selected, // Highlight the selected option by label
                  )}
                  onClick={() => handleSelect(option)}
                >
                  {option.label}
                </div>
              );
            })}
          </div>
        )}
        {hasError && (
          <div className={styles.errorMessage}>{form.errors[field.name]}</div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default Select;
