import { createSlice } from "@reduxjs/toolkit";

export const layoutStore = createSlice({
  name: "layout",
  initialState: {
    menuActive: false,
    notFoundPage: false,
  },
  reducers: {
    updateMenuActive: (state, action) => {
      state.menuActive =
        action.payload !== undefined ? action.payload : !state.menuActive;
    },
    showNotFoundPage: (state, action) => {
      state.notFoundPage = action.payload;
    },
  },
});

export const { updateMenuActive, showNotFoundPage } = layoutStore.actions;

export default layoutStore.reducer;
