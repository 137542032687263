import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import styles from "./NewFinding.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PageLoader from "../../components/Loader/PageLoader";
import Button from "../../components/Button/Button";

const NewFinding = () => {
  const { orgId } = useParams();
  const modules = useSelector((state) => state.user.modules);
  const [loading, setLoading] = useState(true);
  const [customHtmlModule, setCustomHtmlModule] = useState(null);

  let navigate = useNavigate();

  useEffect(() => {
    if (modules && modules[orgId]) {
      const module = modules[orgId].find(
        (module) => module.title === "+ New Finding",
      );
      if (module && module.module === "CustomHtml") {
        setCustomHtmlModule(module);
      }
      setLoading(false);
    }
  }, [modules, orgId]);

  if (loading) {
    return (
      <PageLoader pageLoader text="Loading, thank you for your patience..." />
    );
  }

  return (
    <MainLayout>
      {customHtmlModule ? (
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: customHtmlModule.params.content }}
        />
      ) : (
        <div className={styles.workInProgressWrapper}>
          <span>This module is not configured yet.</span>
          <Button
            text="Go back"
            backgroundColor="#DBD7FC"
            type="button"
            onClick={() => navigate(`/${orgId}`)}
            className={styles.button}
          />
        </div>
      )}
    </MainLayout>
  );
};

export default NewFinding;
