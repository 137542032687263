import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import classNames from "classnames";
import { xor } from "lodash";
import styles from "./MultiSelect.module.scss";
import DownArrowIcon from "../../icons/down-arrow.svg";

function MultiSelect({
  label,
  options,
  onChangeSelectedValues,
  selectedValues,
  disabled,
  className,
}) {
  const [multiSelectActive, setMultiSelectActive] = useState(false);

  const handleCloseMultiSelect = () => {
    setMultiSelectActive(false);
  };

  const handleClearFilters = () => {
    onChangeSelectedValues([]);
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(
          styles.button,
          disabled && styles.disabled,
          selectedValues?.length > 0 && styles.active,
          className,
        )}
        onMouseDown={() =>
          !disabled && setMultiSelectActive(!multiSelectActive)
        }
        onKeyDown={() => !disabled && setMultiSelectActive(!multiSelectActive)}
      >
        <img src={DownArrowIcon} className={styles.downArrowIcon} />
        <span>{label}</span>
        {selectedValues?.length > 0 && (
          <div
            className={styles.crossIconWrapper}
            onClick={handleClearFilters}
          />
        )}
      </div>
      <OutsideClickHandler
        onOutsideClick={handleCloseMultiSelect}
        disabled={!multiSelectActive}
      >
        {multiSelectActive && options && (
          <div className={styles.options}>
            {options.map((option) => (
              <div
                key={option.id}
                className={styles.option}
                onClick={() => {
                  onChangeSelectedValues(xor(selectedValues, [option.id]));
                }}
                onKeyDown={() =>
                  onChangeSelectedValues(xor(selectedValues, [option.id]))
                }
              >
                <div
                  className={classNames(styles.checkbox, {
                    [styles.active]: selectedValues.includes(option.id),
                  })}
                />
                <span>{option.label}</span>
              </div>
            ))}
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
}

export default MultiSelect;
