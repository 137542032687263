import React from "react";
import styles from "./Card.module.scss";
import classNames from "classnames";
import { useNavigate, useParams } from "react-router-dom";

const Card = ({ className, category, modules }) => {
  const { orgId } = useParams();
  const navigate = useNavigate();

  const getBadgeClassName = (category) => {
    switch (category) {
      case "define":
        return styles.badgeDefine;
      case "analyse":
        return styles.badgeAnalyse;
      case "measure":
        return styles.badgeMeasure;
      case "improve":
        return styles.badgeImprove;
      case "control":
        return styles.badgeControl;
      default:
        return "";
    }
  };

  return (
    <div className={classNames(styles.card, className)}>
      <div className={classNames(styles.badge, getBadgeClassName(category))}>
        {category}
      </div>
      <div className={styles.content}>
        {modules.map((module) => (
          <>
            <div
              key={module.title}
              className={styles.moduleItem}
              onClick={() => navigate(`/${orgId}${module.path}`)}
            >
              {module.title}
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default Card;
