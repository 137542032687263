import React, { useEffect, useState } from "react";
import styles from "./Findings.module.scss";
import Table from "../../components/Table/Table";
import { useLocation, useParams } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import PageLoader from "../../components/Loader/PageLoader";
import classNames from "classnames";
import Input from "../../components/Input/Input";
import SearchIcon from "../../icons/search.svg";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import { formatDate } from "../../utils/helpers";
import { showModal } from "../../redux/modalStore";
import { useDispatch, useSelector } from "react-redux";
import FindingsModal from "../../modals/FindingsModal";
import { FINDINGS_LOADER, FINDINGS_MODAL_LOADER } from "../../constants/loader";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Separator from "../../components/Separator/Separator";
import { setLinkedData } from "../../redux/organizationStore";
import Button from "../../components/Button/Button";
import DateRangeFilter from "../../components/DateRangeFilter/DateFIlterRange";

const Findings = () => {
  const [selectedFindingData, setSelectedFindingData] = useState(null);
  const [selectedFindings, setSelectedFindings] = useState([]);
  const [selectedResponsibles, setSelectedResponsibles] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [searchQuery, setSearchQuery] = useState("");

  const { orgId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const loadingContexts = useSelector((state) => state.loader.loadingContexts);
  const modules = useSelector((state) => state.user.modules[orgId]);
  const { meModeActive } = useSelector((state) => state.filters);
  const { risksData, findingsData, data } = useSelector(
    (state) => state.organization,
  );
  const currentUser = useSelector((state) => state.user.email);

  const sessionToken = localStorage.getItem("session_token");

  const isFindingsModalLoading = loadingContexts[FINDINGS_MODAL_LOADER];
  const isFindingsDataLoading = loadingContexts[FINDINGS_LOADER];

  // Extracting path from URL
  const lastIndex = location.pathname.lastIndexOf("/");
  const modifiedPath = location.pathname.substring(lastIndex);

  // Find the module object corresponding to the current path
  const currentModule = modules?.find((mod) => mod.path === modifiedPath);

  const findingsArray = Object.values(findingsData || {});

  // Unique responsible options
  const uniqueResponsibles = findingsArray
    .flatMap((finding) =>
      Array.isArray(finding.responsible)
        ? finding.responsible
        : [finding.responsible],
    )
    .filter(Boolean)
    .filter((value, index, self) => self.indexOf(value) === index)
    .map((responsible, index) => ({ id: responsible, label: responsible }));

  // Risk Levels Options
  const uniqueLevels = findingsArray
    .map((finding) => finding.risk?.level)
    .filter((value, index, self) => value && self.indexOf(value) === index)
    .map((level, index) => ({ id: level, label: level }));

  const handleLevelFilterChange = (newSelectedValues) => {
    setSelectedLevels(newSelectedValues);
  };

  // Status Options
  const uniqueStatuses = findingsArray
    .map((finding) => finding.status)
    .filter((value, index, self) => value && self.indexOf(value) === index)
    .map((status, index) => ({ id: status, label: status }));

  const handleResponsibleFilterChange = (newSelectedValues) => {
    setSelectedResponsibles(newSelectedValues);
  };

  const handleStatusFilterChange = (newSelectedValues) => {
    setSelectedStatuses(newSelectedValues);
  };

  const handleDateRangeFilterChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const filteredData = findingsArray.filter((item) => {
    const {
      responsible,
      status,
      date: itemDate,
      risk,
      reference,
      reporter,
    } = item;
    const dueDate = new Date(itemDate);

    const formattedStartDate = dateRange[0];
    const formattedEndDate = dateRange[1];

    const searchQueryLower = searchQuery.toLowerCase();

    const filters = [
      selectedResponsibles.length === 0 ||
        (Array.isArray(responsible)
          ? responsible.some((r) => selectedResponsibles.includes(r))
          : selectedResponsibles.includes(responsible)),
      selectedStatuses.length === 0 || selectedStatuses.includes(status),
      selectedLevels.length === 0 || selectedLevels.includes(risk?.level),
      (!formattedStartDate && !formattedEndDate) ||
        (dueDate >= formattedStartDate && dueDate <= formattedEndDate),
      !searchQueryLower ||
        item.key?.toLowerCase().includes(searchQueryLower) ||
        item.description?.toLowerCase().includes(searchQueryLower) ||
        reference?.toLowerCase().includes(searchQueryLower) ||
        reporter?.toLowerCase().includes(searchQueryLower) ||
        (Array.isArray(responsible)
          ? responsible.some((r) => r.toLowerCase().includes(searchQueryLower))
          : responsible?.toLowerCase().includes(searchQueryLower)) ||
        status?.toLowerCase().includes(searchQueryLower) ||
        risk?.qms_id?.toLowerCase().includes(searchQueryLower),
      !meModeActive || // Check if meModeActive is false, ignore this filter
        (Array.isArray(responsible)
          ? responsible.includes(currentUser)
          : responsible === currentUser),
    ];

    return filters.every(Boolean);
  });

  const getLinkedData = (finding) => {
    const { linked_improvements = [], linked_audittasks = [] } = finding;

    const improvementsData = data.improvements || {}; // Default to empty object if null

    const improvements = linked_improvements
      .map((key) => {
        if (improvementsData[key]) {
          const improvement = improvementsData[key];
          return improvement;
        } else {
          console.warn(`No improvement found for key ${key}`);
          return undefined;
        }
      })
      .filter(Boolean); // Filter out any undefined values

    const auditTasks = linked_audittasks
      .map((key) => {
        if (data.audit_tasks && data.audit_tasks[key]) {
          const auditTask = data.audit_tasks[key];
          return auditTask;
        } else {
          console.warn(`No audit task found for key ${key}`);
          return undefined;
        }
      })
      .filter(Boolean); // Filter out any undefined values

    return { linked_improvements: improvements, linked_auditTasks: auditTasks };
  };

  const handleRowClick = (row) => {
    const linkedData = getLinkedData(row.original);

    dispatch(setLinkedData(linkedData));
    setSelectedFindingData(row.original);
    dispatch(
      showModal({
        name: "findings_modal",
        data: row.original,
      }),
    );
  };

  const truncateText = (text, maxLength) => {
    if (text?.length <= maxLength) {
      return text;
    }
    return (
      <>
        {text?.substring(0, maxLength)}
        <span className={styles.readMore}>... read more</span>
      </>
    );
  };

  const handleSelectedRowsChange = (selectedRows) => {
    setSelectedFindings(selectedRows);
  };

  const handleOpenNewFindingModal = () => {
    dispatch(
      showModal({
        name: "new_improvement_modal",
        data: selectedFindings,
      }),
    );
  };

  const columns = [
    {
      accessorKey: "select",
      header: ({ table }) => (
        <input
          type="checkbox"
          onClick={(e) => e.stopPropagation()} // Stop click event propagation
          onChange={(e) => table.toggleAllPageRowsSelected(e.target.checked)}
        />
      ),
      size: 50,
      cell: ({ row }) => (
        <input
          type="checkbox"
          checked={row.getIsSelected()}
          onClick={(e) => e.stopPropagation()} // Stop click event propagation
          onChange={() => row.toggleSelected()}
        />
      ),
    },
    {
      accessorKey: "key",
      header: "Key",
      size: 120,
      cell: (props) => <div>{props.getValue()}</div>,
    },
    {
      accessorKey: "reference",
      header: "Reference",
      size: 100,
      cell: (props) => <div>{props.getValue()}</div>,
    },
    {
      accessorKey: "risk.risk_id",
      header: "Risk",
      size: 100,
      cell: (props) => <div>{props.getValue() || "Undefined"}</div>,
    },
    {
      accessorKey: "description",
      header: "Description",
      size: 400,
      cell: (props) => <div>{truncateText(props.getValue(), 80)}</div>,
    },
    {
      accessorKey: "reporter",
      header: "Reporter",
      size: 170,
      cell: (props) => {
        return props.row.original.reporter ? (
          <div>{props.row.original.reporter}</div>
        ) : (
          <div>Not defined</div>
        );
      },
    },
    {
      accessorKey: "responsible",
      header: "Responsible",
      size: 170,
      cell: (props) => {
        return props.row.original.responsible ? (
          <div>{props.row.original.responsible}</div>
        ) : (
          <div>Not defined</div>
        );
      },
    },
    // ...(filteredData.some(
    //   (item) =>
    //     Array.isArray(item.responsible) &&
    //     item.responsible.some((val) => val && val.trim()),
    // )
    //   ? [
    //       {
    //         accessorKey: "responsible",
    //         header: "Responsible",
    //         size: 200,
    //         cell: (props) => {
    //           const value = props.getValue();
    //           const displayValue =
    //             value?.length && value.some((val) => val && val.trim())
    //               ? value.join(", ")
    //               : "Unassigned";
    //           return <div>{displayValue}</div>;
    //         },
    //       },
    //     ]
    //   : []),
    // ...(filteredData.some((item) => item.accountable)
    //   ? [
    //       {
    //         accessorKey: "accountable",
    //         header: "Accountable",
    //         size: 200,
    //         cell: (props) => <div>{props.getValue() || "Unassigned"}</div>,
    //       },
    //     ]
    //   : []),
    // ...(filteredData.some(
    //   (item) =>
    //     Array.isArray(item.consulted) &&
    //     item.consulted.some((val) => val && val.trim()),
    // )
    //   ? [
    //       {
    //         accessorKey: "consulted",
    //         header: "Consulted",
    //         size: 200,
    //         cell: (props) => {
    //           const value = props.getValue();
    //           const displayValue =
    //             value?.length && value.some((val) => val && val.trim())
    //               ? value.join(", ")
    //               : "Unassigned";
    //           return <div>{displayValue}</div>;
    //         },
    //       },
    //     ]
    //   : []),
    // ...(filteredData.some(
    //   (item) =>
    //     Array.isArray(item.informed) &&
    //     item.informed.some((val) => val && val.trim()),
    // )
    //   ? [
    //       {
    //         accessorKey: "informed",
    //         header: "Informed",
    //         size: 200,
    //         cell: (props) => {
    //           const value = props.getValue();
    //           const displayValue =
    //             value?.length && value.some((val) => val && val.trim())
    //               ? value.join(", ")
    //               : "Unassigned";
    //           return <div>{displayValue}</div>;
    //         },
    //       },
    //     ]
    //   : []),
    {
      accessorKey: "status",
      header: "Status",
      size: 120,
      cell: (props) => {
        const status = props.getValue();
        let statusClassName = "";

        switch (status) {
          case "Open":
            statusClassName = styles.openStatus;
            break;
          case "In Progress":
            statusClassName = styles.inProgressStatus;
            break;
          case "In Review":
            statusClassName = styles.closedStatus;
            break;
          case "Done":
            statusClassName = styles.resolvedStatus;
            break;
          default:
            break;
        }

        return (
          <div className={classNames(styles.statusCell, statusClassName)}>
            {status}
          </div>
        );
      },
    },
    {
      accessorKey: "date",
      header: "Date",
      size: 130,
      cell: (props) => <div>{formatDate(props.getValue())}</div>,
    },
  ];

  const risksArray = Object.values(risksData || {});

  // Pie chart URL for risk levels
  const pieChartUrl =
    "https://chart.entropical.io/chart?c=" +
    encodeURIComponent(
      JSON.stringify({
        type: "pie",
        data: {
          datasets: [
            {
              data: [
                findingsArray.filter((r) => r.risk?.level === "low").length,
                findingsArray.filter((r) => r.risk?.level === "medium").length,
                findingsArray.filter((r) => r.risk?.level === "high").length,
              ],
              backgroundColor: ["lightgrey", "orange", "darkred"],
            },
          ],
          labels: ["Low", "Medium", "High"],
        },
        options: {
          title: {
            display: true,
            position: "top",
            fontSize: 20,
            text: "Risk levels",
          },
          plugins: {
            datalabels: {
              display: false,
            },
          },
        },
      }),
    );

  // Bar chart URL for QMS Chapters
  const barChartUrl =
    "https://chart.entropical.io/chart?c=" +
    encodeURIComponent(
      JSON.stringify({
        type: "bar",
        data: {
          labels: risksArray.map((r) => r.qms_id),
          datasets: [
            {
              backgroundColor: "purple",
              data: risksArray
                .map((r) => r.qms_id)
                .map(
                  (chapter) =>
                    findingsArray.filter((r) => r.risk?.qms_id === chapter)
                      .length,
                ),
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: "QMS Chapters",
            fontSize: 20,
          },
          plugins: {
            datalabels: {
              anchor: "center",
              align: "center",
              color: "#fff",
              font: {
                weight: "normal",
                size: 16,
              },
            },
          },
        },
      }),
    );

  return (
    <MainLayout>
      <div className={styles.column}>
        <FindingsModal
          findingData={selectedFindingData}
          isLoading={isFindingsModalLoading}
        />
        {isFindingsDataLoading && (
          <PageLoader text="Loading, it will be ready shortly" />
        )}
        {!isFindingsDataLoading && (
          <>
            <Breadcrumbs data={currentModule} />
            <Separator />
            <div className={styles.findingsContainer}>
              <div className={styles.rowWrapper}>
                <div className={styles.filtersWrapper}>
                  <Button
                    text="Create New Improvement Project"
                    className={styles.openModalButton}
                    onClick={handleOpenNewFindingModal}
                    disabled={selectedFindings.length === 0} // Disable button if no rows are selected
                  />
                  <MultiSelect
                    label={
                      selectedResponsibles.length > 0
                        ? selectedResponsibles.join(", ")
                        : "Responsible"
                    }
                    options={uniqueResponsibles}
                    onChangeSelectedValues={handleResponsibleFilterChange}
                    selectedValues={selectedResponsibles}
                    disabled={!findingsArray.length}
                  />
                  <MultiSelect
                    label={
                      selectedStatuses.length > 0
                        ? selectedStatuses.join(", ")
                        : "Status"
                    }
                    options={uniqueStatuses}
                    onChangeSelectedValues={handleStatusFilterChange}
                    selectedValues={selectedStatuses}
                    disabled={!findingsArray.length}
                  />
                  <MultiSelect
                    label={
                      selectedLevels.length > 0
                        ? selectedLevels.join(", ")
                        : "Risk levels"
                    }
                    options={uniqueLevels}
                    onChangeSelectedValues={handleLevelFilterChange}
                    selectedValues={selectedLevels}
                    disabled={!findingsArray.length}
                  />
                  <DateRangeFilter
                    handleDateRangeChange={handleDateRangeFilterChange}
                    dateRange={dateRange}
                    isClearable
                  />
                </div>
                <Input
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className={styles.input}
                  icon={SearchIcon}
                />
              </div>
              <Table
                columns={columns}
                data={filteredData}
                onRowClick={handleRowClick}
                onSelectedRowsChange={handleSelectedRowsChange}
                rowHover
              />
              {!filteredData.length && (
                <div className={styles.noData}>
                  Sorry, there is no data to display.
                </div>
              )}
              <Separator className={styles.separator} />
              <div className={styles.chartWrapper}>
                {/* Display the charts */}
                <img
                  src={pieChartUrl}
                  alt="Risk Levels Pie Chart"
                  className={styles.chartImage}
                />
                <img
                  src={barChartUrl}
                  alt="QMS Chapters Bar Chart"
                  className={styles.chartImage}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default Findings;
