import { createSlice } from "@reduxjs/toolkit";

export const findingsStore = createSlice({
  name: "findings",
  initialState: {
    findingsData: [],
    linkedData: [],
  },
  reducers: {
    setFindingsData: (state, action) => {
      state.findingsData = action.payload;
    },
    setLinkedData: (state, action) => {
      state.linkedData = action.payload;
    },
  },
});

export const { setFindingsData, setLinkedData } = findingsStore.actions;

export default findingsStore.reducer;
