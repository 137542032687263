import React, { useState } from "react";
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import NewEmailIllustration from "../../icons/new-email-illustration.svg";
import styles from "./Login.module.scss";
import axiosInstance from "../../utils/utils";

const Login = () => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const [emailSent, setEmailSent] = useState(null);

  const handleSubmit = (values, actions) => {
    const options = {
      method: "POST",
      url: "/auth/session",
      params: {
        email: values.email,
      },
    };

    return axiosInstance
      .request(options)
      .then(() => {
        setEmailSent(true);
      })
      .catch((error) => {
        console.error("Login error:", error);
        // Handle error: for example, show error message to user
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <AuthLayout>
      {!emailSent && (
        <Formik
          initialValues={{ email: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, isValid }) => (
            <Form>
              <div>
                <Field
                  name="email"
                  type="text"
                  component={Input}
                  placeholder="Enter your email address here"
                  className={styles.input}
                />
              </div>
              <Button
                type="submit"
                disabled={isSubmitting || !values.email || !isValid}
                text="Login"
                className={styles.button}
              >
                {isSubmitting ? "Logging in..." : "Login"}
              </Button>
            </Form>
          )}
        </Formik>
      )}
      {emailSent && (
        <div className={styles.wrapper}>
          <img src={NewEmailIllustration} alt="Illustration of new email" />
          <span className={styles.text}>
            We've sent a login link to your email. <br /> Check your inbox and
            hop right in!
          </span>
        </div>
      )}
    </AuthLayout>
  );
};

export default Login;
