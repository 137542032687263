import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authStore from "../redux/authStore";
import userStore from "../redux/userStore";
import layoutStore from "../redux/layoutStore";
import confluenceStore from "../redux/confluenceStore";
import loaderStore from "../redux/loaderStore";
import modalStore from "../redux/modalStore";
import improvementsStore from "../redux/improvementsStore";
import modalConfluenceStore from "../redux/modalConfluenceStore";
import qualityMeasurementSystemStore from "../redux/qualityMeasurementSystemStore";
import organizationStore from "../redux/organizationStore";
import findingsStore from "../redux/findingsStore";
import auditsStore from "../redux/auditsStore";
import filterStore from "../redux/filterStore";

const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: ["organization"], // only organization will be persisted
};

const rootReducer = combineReducers({
  auth: authStore,
  user: userStore,
  audits: auditsStore,
  layout: layoutStore,
  confluence: confluenceStore,
  loader: loaderStore,
  modal: modalStore,
  improvements: improvementsStore,
  modalConfluence: modalConfluenceStore,
  qualityMeasurementSystem: qualityMeasurementSystemStore,
  organization: organizationStore,
  findings: findingsStore,
  filters: filterStore,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
export default store;
