import { createSlice } from "@reduxjs/toolkit";

export const confluenceStore = createSlice({
  name: "confluence",
  initialState: {
    confluenceTreeData: {},
    confluencePageData: {},
  },
  reducers: {
    setConfluenceTree: (state, action) => {
      state.confluenceTreeData = action.payload;
    },
    setConfluencePage: (state, action) => {
      state.confluencePageData = action.payload;
    },
    resetConfluenceStore: (state, action) => {
      state.confluenceTreeData = {};
      state.confluencePageData = {};
    },
  },
});

export const { setConfluenceTree, setConfluencePage, resetConfluenceStore } =
  confluenceStore.actions;

export default confluenceStore.reducer;
