import React, { useState } from "react";
import classNames from "classnames";
import styles from "./Accordion.module.scss";
import PlusIcon from "../../icons/plus-circle.svg";
import MinusIcon from "../../icons/minus-circle.svg";
import ArrowIcon from "../../icons/arrow.svg";
import QuestionMarkIcon from "../../icons/question-mark.svg";
import Separator from "../Separator/Separator";
import { Tooltip } from "react-tooltip";
import { Field } from "formik";
import Upload from "../UploadInput/Upload";
import { getTruncatedContent } from "../../utils/helpers";

const Accordion = ({ items, openAllByDefault, icons, tooltip, status }) => {
  const [openedAccordions, setOpenedAccordions] = useState(
    openAllByDefault ? items.map((_, index) => index) : [],
  );

  const toggleAccordion = (index) => {
    setOpenedAccordions(
      (prevAccordions) =>
        prevAccordions.includes(index)
          ? prevAccordions.filter((i) => i !== index) // Close if already open
          : [...prevAccordions, index], // Open if closed
    );
  };

  return (
    <div>
      {items?.map((item, index) => {
        const isOpen = openedAccordions.includes(index);
        const nonNullAnswers = Array.isArray(item.answer.answers)
          ? item.answer.answers.filter((answer) => answer !== null)
          : [];
        const fullAnswer = nonNullAnswers.join(" ");
        const tooltipContent = getTruncatedContent(fullAnswer, 80);

        return (
          <div className={classNames(styles.container)} key={index}>
            <div
              className={styles.questions}
              data-tooltip-id={`tooltip-${index}`}
              data-tooltip-content={tooltipContent}
            >
              {/*{icons && <img src={PlusIcon} alt="Plus icon" />}*/}
              <div className={styles.question}>
                <img src={QuestionMarkIcon} alt="Question mark icon" />
                <span>{item.question}</span>
              </div>
              {(nonNullAnswers.length > 0 || status !== "Done") &&
                (!isOpen ? (
                  <img
                    src={PlusIcon}
                    alt="Open icon"
                    className={styles.icon}
                    onClick={() => toggleAccordion(index)}
                  />
                ) : (
                  <img
                    src={MinusIcon}
                    alt="Close icon"
                    className={styles.icon}
                    onClick={() => toggleAccordion(index)}
                  />
                ))}
            </div>

            {/* Update the condition to check for undefined or empty answers */}
            {isOpen && (status !== "Done" || nonNullAnswers.length > 0) && (
              <div className={styles.content}>
                <div className={styles.accordionItems}>
                  {nonNullAnswers.length > 0 && (
                    <div className={styles.answers}>
                      {nonNullAnswers.map((answer, idx) => (
                        <div key={idx} className={styles.answer}>
                          {icons && (
                            <img
                              src={ArrowIcon}
                              alt="Arrow icon"
                              className={styles.arrowIcon}
                            />
                          )}
                          {item.answer.type === "text" ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.answer.modifyDescription(answer),
                              }}
                            />
                          ) : (
                            <a
                              href={answer}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {answer}
                            </a>
                          )}
                        </div>
                      ))}
                      {status !== "Done" && (
                        <Separator className={styles.separator} />
                      )}
                    </div>
                  )}
                  {item.answer.editable && (
                    <>
                      {item.answer.type === "text" ? (
                        <Field
                          name={`responses.${item.answer.questionId}`}
                          as="textarea"
                          className={styles.textInput}
                        />
                      ) : (
                        <Upload questionId={item.answer.questionId} />
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
            {items.length - 1 !== index && <Separator />}
            {!isOpen && tooltip && (
              <Tooltip
                id={`tooltip-${index}`}
                key={`tooltip-${index}`}
                className={styles.tooltip}
                place="left-start"
                noArrow
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Accordion;
