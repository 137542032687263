import React from "react";
import styles from "./Breadcrumbs.module.scss";
import RightArrow from "../../icons/arrow-right.svg";
import House from "../../icons/house.svg";
import classNames from "classnames";
import { useNavigate, useParams } from "react-router-dom";

const Breadcrumbs = ({ data }) => {
  const navigate = useNavigate();
  const { orgId } = useParams();

  const getBadgeClassName = (category) => {
    switch (category) {
      case "define":
        return styles.badgeDefine;
      case "analyse":
        return styles.badgeAnalyse;
      case "measure":
        return styles.badgeMeasure;
      case "improve":
        return styles.badgeImprove;
      case "control":
        return styles.badgeControl;
      default:
        return styles.badgeGeneral;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.home} onClick={() => navigate(`/${orgId}`)}>
        <img src={House} alt="Home icon" className={styles.icon} />
        Home
      </div>
      <img src={RightArrow} alt="Arrow icon" className={styles.icon} />
      <div
        className={classNames(
          styles.badge,
          getBadgeClassName(data?.category?.toLowerCase()),
        )}
      >
        {data?.category || "General"}
      </div>
      <img src={RightArrow} alt="Arrow icon" className={styles.icon} />
      <div className={styles.title}>{data?.title}</div>
    </div>
  );
};

export default Breadcrumbs;
