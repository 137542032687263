import classNames from "classnames";
import React from "react";
import styles from "./Separator.module.scss";

const Separator = ({ vertical, className }) => {
  return (
    <div
      className={classNames(
        styles.separator,
        vertical && styles.vertical,
        className,
      )}
    />
  );
};

export default Separator;
