import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import styles from "./RiskAnalysis.module.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PageLoader from "../../components/Loader/PageLoader";
import Button from "../../components/Button/Button";
import WorkInProgressIllustration from "../../icons/work-in-progress-illustration.svg";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Separator from "../../components/Separator/Separator";

const RiskAnalysis = () => {
  const { orgId } = useParams();
  let navigate = useNavigate();
  const location = useLocation();

  const modules = useSelector((state) => state.user.modules);
  const [loading, setLoading] = useState(true);
  const [customHtmlModule, setCustomHtmlModule] = useState(null);

  // Extracting path from URL
  const lastIndex = location.pathname.lastIndexOf("/");
  const modifiedPath = location.pathname.substring(lastIndex);

  // Find the module object corresponding to the current path
  const currentModule = modules[orgId]?.find(
    (mod) => mod.path === modifiedPath,
  );

  useEffect(() => {
    if (modules && modules[orgId]) {
      const module = modules[orgId].find(
        (module) => module.title === "Risk Analysis",
      );
      if (module && module.module === "CustomHtml") {
        setCustomHtmlModule(module);
      }
      setLoading(false);
    }
  }, [modules, orgId]);

  if (loading) {
    return (
      <PageLoader pageLoader text="Loading, thank you for your patience..." />
    );
  }

  return (
    <MainLayout>
      <div className={styles.column}>
        <Breadcrumbs data={currentModule} />
        <Separator />
        {customHtmlModule ? (
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: customHtmlModule.params.content,
            }}
          />
        ) : (
          <div className={styles.workInProgressWrapper}>
            <img
              src={WorkInProgressIllustration}
              alt="Work in progress illustration"
            />
            <span>This module is not configured yet.</span>
            <Button
              text="Go back"
              backgroundColor="#DBD7FC"
              type="button"
              onClick={() => navigate(`/${orgId}`)}
              className={styles.button}
            />
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default RiskAnalysis;
