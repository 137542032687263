import { createSlice } from "@reduxjs/toolkit";

export const filterStore = createSlice({
  name: "filters",
  initialState: {
    meModeActive: false,
  },
  reducers: {
    toggleMeMode: (state) => {
      state.meModeActive = !state.meModeActive;
    },
  },
});

export const { toggleMeMode } = filterStore.actions;

export default filterStore.reducer;
