import React, { useEffect, useRef } from "react";
import styles from "./MainLayout.module.scss";
import Header from "../Header/Header";
import SideBar from "../SideBar/SideBar";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useLocation, useParams } from "react-router-dom";
import { updateMenuActive } from "../../redux/layoutStore";
import PageLoader from "../../components/Loader/PageLoader";
import LogoutModal from "../../modals/LogoutModal";
import OrganizationSwitchModal from "../../modals/OrganizationSwitchModal";
import { CONFLUENCE_TREE_LOADER, PAGE_LOADER } from "../../constants/loader";
import ErrorModal from "../../modals/ErrorModal";
import FloatingBar from "../../components/FloatingBar/FloatingBar";
import NewFindingModal from "../../modals/NewFindingModal";
import NewImprovementProjectModal from "../../modals/NewImprovementProjectModal";

const MainLayout = (props) => {
  const menuActive = useSelector((state) => state.layout.menuActive);
  const loadingContexts = useSelector((state) => state.loader.loadingContexts);
  const openedModal = useSelector((state) => state.modal.openedModal);

  const isConfluenceTreeLoading = loadingContexts[CONFLUENCE_TREE_LOADER];
  const isPageLoading = loadingContexts[PAGE_LOADER];

  const dispatch = useDispatch();
  const location = useLocation();
  const menuIconRef = useRef(null);
  // const orgId = localStorage.getItem("orgId");
  const { orgId } = useParams();

  // closing sidebar when navigating through header or "back" button in browser
  useEffect(() => {
    dispatch(updateMenuActive(false));
  }, [location.pathname]);

  return (
    <>
      {isPageLoading ? (
        <PageLoader text="We are gathering information, it will be ready shortly" />
      ) : (
        <>
          <Header menuIconRef={menuIconRef} />
          {isConfluenceTreeLoading ? (
            <PageLoader text="Loading, it will be ready shortly" />
          ) : (
            <>
              <LogoutModal />
              <OrganizationSwitchModal />
              <ErrorModal />
              <NewFindingModal />
              <NewImprovementProjectModal />
              {menuActive && <SideBar menuIconRef={menuIconRef} />}
              <div
                className={classNames(
                  styles.main,
                  menuActive && styles.overlay,
                )}
              >
                <div
                  className={classNames(
                    styles.content,
                    menuActive && styles.overlay,
                  )}
                >
                  {props.children}
                </div>
              </div>
            </>
          )}
          {openedModal?.name !== "audits_modal" && <FloatingBar />}
        </>
      )}
    </>
  );
};

export default MainLayout;
