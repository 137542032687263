import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateFilter.scss";
import CalendarIcon from "../../icons/calendar.svg";

const DateFilter = ({
  startDate,
  handleDateFilterChange,
  isClearable,
  maxDate,
}) => {
  const CustomButton = React.forwardRef(({ value, onClick }, ref) => (
    <button type="button" className="customInput" onClick={onClick} ref={ref}>
      {value ? value : "Select Date"}
      <img src={CalendarIcon} alt="Calendar icon" className="calendarIcon" />
    </button>
  ));

  return (
    <DatePicker
      type="button"
      showIcon
      selected={startDate}
      onChange={handleDateFilterChange}
      startDate={startDate}
      dateFormat="dd/MM/yyyy"
      wrapperClassName="datePicker"
      icon={
        <img src={CalendarIcon} alt="Calendar icon" className="calendarIcon" />
      }
      isClearable={isClearable}
      customInput={<CustomButton />}
      maxDate={maxDate}
    />
  );
};

export default DateFilter;
