import { createSlice } from "@reduxjs/toolkit";

export const qualityMeasurementsSystemStore = createSlice({
  name: "qualityMeasurementSystem",
  initialState: {
    measureData: [],
    riskAnalyseData: [],
    improvementsData: [],
    defineData: [],
  },
  reducers: {
    setMeasureData: (state, action) => {
      state.measureData = action.payload;
    },
    setRiskAnalyseData: (state, action) => {
      state.riskAnalyseData = action.payload;
    },
    setImprovementsData: (state, action) => {
      state.improvementsData = action.payload;
    },
    setDefineData: (state, action) => {
      state.defineData = action.payload;
    },
  },
});

export const {
  setMeasureData,
  setRiskAnalyseData,
  setImprovementsData,
  setDefineData,
} = qualityMeasurementsSystemStore.actions;

export default qualityMeasurementsSystemStore.reducer;
