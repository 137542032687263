import React, { useEffect } from "react";
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import Select from "../../components/Select/Select";
import Button from "../../components/Button/Button";
import styles from "./Organizations.module.scss";
import axiosInstance from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../redux/userStore";
import { useLocation, useNavigate } from "react-router-dom";
import { showModal } from "../../redux/modalStore";
import Loader from "../../components/Loader/Loader";
import { getOrganizationData } from "../../actions/organization";

const Organizations = () => {
  const validationSchema = Yup.object().shape({
    organization: Yup.string().required("Required"),
  });

  const organizations = useSelector((state) => state.user.organizations);

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();

  const sessionToken = localStorage.getItem("session_token");

  const getUserData = (sessionToken, refresh = false) => {
    const refreshQuery = refresh ? "&refresh=true" : "";
    const options = {
      method: "GET",
      url: `/auth/session?session_token=${sessionToken}${refreshQuery}`,
    };

    return axiosInstance
      .request(options)
      .then((response) => {
        dispatch(setUserData(response.data));
      })
      .catch(() => {
        dispatch(
          showModal({
            name: "error_modal",
          }),
        );
      });
  };

  useEffect(() => {
    // Clear orgId when on /organizations page to prevent redirect on refresh
    localStorage.removeItem("orgId");
    if (sessionToken) {
      if (location.pathname === "/organizations") {
        getUserData(sessionToken, true);
      }
    }
  }, [dispatch, sessionToken, location.pathname]);

  const handleSubmit = (values) => {
    const selectedOrganization = transformedOrganizations.find(
      (org) => org.label === values.organization,
    );

    if (selectedOrganization) {
      const organizationId = selectedOrganization.id;

      // Dispatch the action with the organization ID instead of the label
      dispatch(
        getOrganizationData(organizationId, sessionToken, navigate, true),
      );
    }
  };

  // Transforming organizations object into an array
  const transformedOrganizations = Object.values(organizations)
    .map((org) => ({
      id: org.UUID,
      label: org.Name,
    }))
    .sort((a, b) => {
      const labelA = a?.label?.toUpperCase();
      const labelB = b?.label?.toUpperCase();
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    });

  return (
    <AuthLayout>
      <Formik
        initialValues={{ organization: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid, values }) => {
          return (
            <>
              {isSubmitting ? (
                <Loader text="We are gathering organization data, please wait." />
              ) : (
                <Form>
                  <div className={styles.field}>
                    <Field
                      name="organization"
                      component={Select}
                      text="Select your organization"
                      options={transformedOrganizations}
                    />
                  </div>
                  <Button
                    type="submit"
                    text="Submit"
                    className={styles.button}
                  />
                </Form>
              )}
            </>
          );
        }}
      </Formik>
    </AuthLayout>
  );
};

export default Organizations;
