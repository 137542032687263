import React from "react";
import styles from "./Loader.module.scss";
import { FallingLines } from "react-loader-spinner";

const Loader = ({ text }) => {
  return (
    <div className={styles.loader}>
      <FallingLines
        color="#007940"
        width="80"
        visible={true}
        ariaLabel="falling-circles-loading"
      />
      <span>{text}</span>
    </div>
  );
};

export default Loader;
