import React from "react";
import Modal from "../components/Modal/Modal";
import styles from "./OrganizationSwitchModal.module.scss";
import Button from "../components/Button/Button";
import classNames from "classnames";
import LogoutIllustration from "../icons/are-you-sure.svg";
import { useNavigate } from "react-router-dom";
import { hideModal } from "../redux/modalStore";
import { useDispatch } from "react-redux";

const OrganizationSwitchModal = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(hideModal(null));
  };

  return (
    <Modal
      name="organizations_switch_modal"
      title="You are switching organizations, do you wish to proceed?"
    >
      <div className={styles.content}>
        <img
          src={LogoutIllustration}
          alt="Woman thinking"
          className={styles.illustration}
        />
        <div className={styles.buttonRow}>
          <Button
            className={classNames(styles.button, styles.cancel)}
            text="Cancel"
            onClick={handleCloseModal}
          />
          <Button
            className={styles.button}
            text="Yes, proceed"
            onClick={() => {
              navigate("/organizations");
              handleCloseModal();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default OrganizationSwitchModal;
