import React from "react";
import { useField } from "formik";
import styles from "./FormikCheckBox.module.scss";
import CheckIcon from "../../../icons/check-yellow.svg";

const FormikCheckbox = ({ label, ...props }) => {
  const [field, meta, helpers] = useField({ ...props, type: "checkbox" });
  const { setValue } = helpers;

  const toggleCheckbox = () => {
    const newValue = field.value === "Open" ? "Done" : "Open";
    setValue(newValue);
  };

  return (
    <div className={styles.checkboxContainer}>
      <div
        className={`${styles.checkbox} ${
          field.value === "Open" ? styles.checked : ""
        }`}
        onClick={toggleCheckbox}
      >
        {field.value === "Open" && (
          <img src={CheckIcon} className={styles.checkboxTick} />
        )}
      </div>
      <label onClick={toggleCheckbox} className={styles.checkboxLabel}>
        {label}
      </label>
    </div>
  );
};

export default FormikCheckbox;
