import React from "react";
import styles from "./Button.module.scss";
import classNames from "classnames";

const Button = ({ text, type, onClick, disabled, width, className, icon }) => {
  return (
    <button
      className={classNames(
        styles.buttonWrapper,
        className,
        disabled && styles.disabled,
      )}
      type={type}
      onClick={onClick}
      style={{ width: width }}
      disabled={disabled}
    >
      {icon && <img src={icon} alt="Button icon" />}
      {text}
    </button>
  );
};

export default Button;
