import React, { useState } from "react";
import styles from "./NewImprovementProjectModal.module.scss";
import Modal from "../components/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { hideModal } from "../redux/modalStore";
import { Field, Form, Formik } from "formik";
import Textarea from "../components/Textarea/Textarea";
import DatePickerField from "../components/FormikComponents/DatePickerField";
import Input from "../components/Input/Input";
import Button from "../components/Button/Button";
import * as Yup from "yup";
import axiosInstance from "../utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import { getOrganizationData } from "../actions/organization";

const NewImprovementProjectModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openedModal = useSelector((state) => state.modal.openedModal);
  const [loading, setLoading] = useState(false);

  const { orgId } = useParams();
  const sessionToken = localStorage.getItem("session_token");

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name cannot be empty."),
    description: Yup.string().required("Please provide a description."),
  });

  const handleCloseModal = () => {
    dispatch(hideModal(null));
  };

  const formatDate = (inputDate) => {
    if (!inputDate) {
      return new Date().toISOString().split("T")[0];
    }
    return new Date(inputDate).toISOString().split("T")[0];
  };

  const findingKeys = Array.isArray(openedModal?.data)
    ? openedModal.data.map((finding) => finding.key)
    : [];

  const convertedArray = findingKeys?.map((item) => {
    // Extract the numeric part of the string using a regular expression
    const match = item.match(/\d+/);

    // Check if the match was successful
    if (match) {
      // Convert the extracted numeric part to a number
      return parseInt(match[0], 10);
    } else {
      // Handle the case where no numeric part was found
      throw new Error(`Unable to parse the string as an integer: ${item}`);
    }
  });

  const handleSubmit = async (values, actions) => {
    try {
      setLoading(true);

      // First POST request
      const createImprovementResponse = await axiosInstance.post(
        `/nocodb/improvements?org_id=${orgId}`,
        [
          {
            name: values.name,
            description: values.description,
            date: formatDate(values.date),
          },
        ],
        {
          headers: { "session-token": sessionToken },
        },
      );

      const targetId = createImprovementResponse.data; // Assuming the response directly gives you the ID

      // Second POST request
      const linkFindingsResponse = await axiosInstance.post(
        `/nocodb/link_findings?org_id=${orgId}&link_target=Improvement&target_id=${targetId}`,
        convertedArray, // Sending the keys from all objects in openedModal.data
        {
          headers: { "session-token": sessionToken },
        },
      );

      // Handle success (e.g., close modal, show success message)
      handleCloseModal();
    } catch (error) {
      console.error("Error creating improvement project:", error);
      // Handle error (e.g., show error message)
    } finally {
      await dispatch(
        getOrganizationData(
          orgId,
          sessionToken,
          navigate,
          false,
          false,
          true,
          false,
        ),
      );

      setLoading(false);
      dispatch(hideModal());
      actions.setSubmitting(false);
    }
  };

  return (
    <Modal
      name="new_improvement_modal"
      title="New Improvement Project"
      onClose={handleCloseModal}
      width="55vw"
    >
      <Formik
        initialValues={{
          name: "",
          description: "",
          date: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          setLoading(true);
          handleSubmit(values, actions);
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className={styles.content}>
              <div className={styles.row}>
                <label htmlFor="name">Name</label>
                <Field name="name" component={Input} className={styles.input} />
              </div>
              <div className={styles.description}>
                <label htmlFor="description">Description</label>
                <Field name="description" component={Textarea} />
              </div>
              <div className={styles.row}>
                <div className={styles.dueDate}>
                  <label htmlFor="date">Due Date</label>
                  <Field
                    name="date"
                    component={DatePickerField}
                    validateOnBlur={true}
                    validateOnChange={false}
                    maxDate={null}
                  />
                </div>
              </div>
              <Button
                text={loading ? "Adding..." : "Add New Improvement Project"}
                type="submit"
                className={styles.submitButton}
                disabled={loading}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default NewImprovementProjectModal;
