import React from "react";
import classNames from "classnames";
import styles from "./Input.module.scss";

const Input = ({ className, icon, field, form, ...props }) => {
  return (
    <div className={styles.wrapper}>
      <input
        {...field}
        {...props}
        className={classNames(styles.inputContainer, className, {
          [styles.errorBorder]:
            form?.errors[field.name] && form.touched[field.name],
        })}
      />
      {form?.errors[field.name] && form.touched[field.name] && (
        <div className={styles.error}>{form.errors[field.name]}</div>
      )}
    </div>
  );
};

export default Input;
