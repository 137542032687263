import React, { useEffect, useState } from "react";
import { uniqBy } from "lodash";
import styles from "./Pill.module.scss";
import CloseIcon from "../../icons/close.svg";

function Pill({ field, form, limit, placeholder }) {
  const value = field.value || []; // `value` could be an array or a string
  const [prompt, setPrompt] = useState("");

  // Clean the value to ensure no empty strings are present
  const cleanValue = (value) => {
    return (value || []).filter((tag) => tag.trim().length > 0);
  };

  useEffect(() => {
    // When the component mounts or the field.value changes, clean it up
    const cleanedTags = cleanValue(
      Array.isArray(field.value) ? field.value : [field.value],
    );
    if (cleanedTags.length !== field.value.length) {
      form.setFieldValue(field.name, cleanedTags);
    }
  }, [field.value, form, field.name]);

  const handleOnKeyDown = (e) => {
    if (e.code === "Enter") {
      e.preventDefault();
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const splitKeywords = (text) => {
    const tags = text
      .split(",")
      .filter((tag) => tag.trim().length > 0 && isValidEmail(tag));

    if (tags.length === 0) {
      return;
    }

    let newTags = uniqBy([...value, ...tags], (tag) => tag.toLowerCase());

    if (limit) {
      newTags = newTags.slice(0, limit);
    }

    form.setFieldValue(field.name, newTags);
    setPrompt("");
  };

  const handleOnKeyUp = (e) => {
    if (["Comma", "Enter"].includes(e.code)) {
      splitKeywords(prompt);
    }
  };

  const handleOnChange = (e) => {
    const lastCharacter = e.target.value[e.target.value.length - 1];
    if (lastCharacter === ",") {
      const tags = prompt.split(",").filter((tag) => tag.length);
      if (
        tags[tags.length - 1]?.length < 2 ||
        !isValidEmail(tags[tags.length - 1])
      ) {
        return;
      }
    }
    setPrompt(e.target.value);
  };

  const handleDeleteTag = (tag) => {
    const newTags = Array.isArray(value)
      ? value.filter((item) => item !== tag)
      : [];
    form.setFieldValue(field.name, newTags);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.input}>
        {/* Check if value is an array before mapping */}
        {Array.isArray(value) ? (
          value.map((tag) => (
            <div className={styles.pill} key={tag}>
              {tag}
              <div
                tabIndex={0}
                role="button"
                onClick={() => handleDeleteTag(tag)}
                onKeyDown={() => handleDeleteTag(tag)}
                className={styles.iconWrapper}
              >
                <img src={CloseIcon} className={styles.icon} />
              </div>
            </div>
          ))
        ) : (
          // If value is not an array, render as a string
          <div className={styles.pill}>{value}</div>
        )}
        {(!limit || (Array.isArray(value) && value.length < limit)) && (
          <input
            type="text"
            placeholder={placeholder}
            value={prompt}
            onKeyDown={handleOnKeyDown}
            onKeyUp={handleOnKeyUp}
            onChange={handleOnChange}
            onBlur={() => splitKeywords(prompt)}
            onPaste={(event) => {
              splitKeywords(event.clipboardData.getData("text"));
              event.preventDefault();
            }}
          />
        )}
      </div>
    </div>
  );
}

export default Pill;
