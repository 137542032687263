import React, { useEffect } from "react";
import styles from "./SideBar.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { updateMenuActive } from "../../redux/layoutStore";
import Button from "../../components/Button/Button";
import classNames from "classnames";
import { resetConfluenceStore } from "../../redux/confluenceStore";
import { showModal } from "../../redux/modalStore";
import OutsideClickHandler from "react-outside-click-handler";
import Separator from "../../components/Separator/Separator";

const SideBar = ({ menuIconRef }) => {
  const { orgId } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const user = useSelector((state) => state.user.user);
  const email = useSelector((state) => state.user.email);
  const menuActive = useSelector((state) => state.layout.menuActive);

  //Getting data through orgId; If in some case orgId is not always in URL, set orgId in local storage or use other method;
  const modules = useSelector((state) => state.user.modules[orgId]);

  // Group modules by category
  const groupedModules = modules?.reduce((acc, module) => {
    const category = module.category || "";
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(module);
    return acc;
  }, {});

  // Define the order of categories
  const categoryOrder = ["Define", "Measure", "Analyse", "Improve", "Control"];

  // Sort categories based on the defined order
  const sortedCategories = Object.keys(groupedModules || {}).sort(
    (a, b) => categoryOrder.indexOf(a) - categoryOrder.indexOf(b),
  );

  // Currently valid routes
  const validRoutes = [
    "/organizations",
    "/",
    "/qms",
    "/resources",
    "/knowledge-base",
    "/management-reports",
    "/audits",
    "/improvement-projects",
    "/findings",
    "/risk-analysis",
    "/manage-audits",
    "/admin",
  ];

  const handleNavigation = (path) => {
    // Check if the clicked item is already active
    if (isCurrentPath(path)) {
      return;
    }

    dispatch(resetConfluenceStore());
    navigate(`/${orgId}${path}`);
    dispatch(updateMenuActive(false));
  };

  const handleOpenLogoutModal = () => {
    dispatch(
      showModal({
        name: "logout_modal",
      }),
    );
  };

  const isPathValid = (path) => {
    const resolvedRoutes = validRoutes.map((route) =>
      route.replace(":orgId", orgId),
    );
    return resolvedRoutes.includes(path);
  };

  // Check if the current path matches the module path
  const isCurrentPath = (path) => {
    return location.pathname === `/${orgId}` && path === "/"
      ? true
      : location.pathname === `/${orgId}${path}`;
  };

  // useEffect(() => {
  //   if (!menuActive) {
  //
  //   }
  // }, [menuActive]);

  const closeSidebar = (e) => {
    if (menuIconRef.current && menuIconRef.current.contains(e.target)) {
      return;
    }
    dispatch(updateMenuActive(false));
  };

  const getBadgeClassName = (category) => {
    switch (category) {
      case "define":
        return styles.badgeDefine;
      case "measure":
        return styles.badgeMeasure;
      case "analyse":
        return styles.badgeAnalyse;
      case "improve":
        return styles.badgeImprove;
      case "control":
        return styles.badgeControl;
      default:
        return "";
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={closeSidebar}>
      <div className={styles.wrapper}>
        <div className={styles.profileInformation}>
          <span>{user?.Name}</span>
          <span>{user?.Email}</span>
          <span>{user?.Role}</span>
          <Separator className={styles.separator} />
        </div>
        {groupedModules &&
          sortedCategories.map((category) => (
            <div key={category}>
              {category && (
                <div
                  className={classNames(
                    styles.badge,
                    getBadgeClassName(category.toLowerCase()),
                  )}
                >
                  {category}
                </div>
              )}
              {groupedModules[category].map((module) => {
                const isValid = isPathValid(module.path);
                const isActive = isCurrentPath(module.path);
                return (
                  <div
                    key={module.path}
                    className={classNames(
                      styles.items,
                      isValid ? styles.enabled : styles.disabled,
                      isActive && styles.active,
                    )}
                    onClick={() => isValid && handleNavigation(module.path)}
                  >
                    {module?.title}
                  </div>
                );
              })}
            </div>
          ))}
        <Button
          text="Log out"
          type="button"
          className={styles.button}
          onClick={handleOpenLogoutModal}
        />
      </div>
    </OutsideClickHandler>
  );
};

export default SideBar;
