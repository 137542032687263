import React from "react";
import Modal from "../components/Modal/Modal";
import styles from "./ErrorModal.module.scss";
import ErrorIllustration from "../icons/error-illustration.svg";

const ErrorModal = () => {
  return (
    <Modal name="error_modal" title="Ooops!" width="50vw">
      <div className={styles.content}>
        <img
          src={ErrorIllustration}
          alt="Woman thinking"
          className={styles.illustration}
        />
        <div className={styles.text}>
          An unexpected error occurred. Please try again later.
          <br />
          If the problem persists, contact support.
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
