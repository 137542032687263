import React, { useEffect, useState } from "react";
import styles from "./Header.module.scss";
import LogoWhite from "../../icons/logo-white.svg";
import MenuIcon from "../../icons/menu.svg";
import PlusIcon from "../../icons/plus.svg";
import RefreshIcon from "../../icons/refresh.svg";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { updateMenuActive } from "../../redux/layoutStore";
import { useNavigate, useParams } from "react-router-dom";
import { showModal } from "../../redux/modalStore";
import Button from "../../components/Button/Button";
import { getOrganizationData } from "../../actions/organization";
import { setLoadingContext } from "../../redux/loaderStore";
import { REFRESH_BUTTON_LOADER } from "../../constants/loader";

const Header = ({ menuIconRef }) => {
  const menuActive = useSelector((state) => state.layout.menuActive);
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [hasTransparentBackground, setHasTransparentBackground] =
    useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isLogoError, setIsLogoError] = useState(false);

  const { orgId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sessionToken = localStorage.getItem("session_token");

  //Getting data through orgId; If in some case orgId is not always in URL, set orgId in local storage or use other method;
  const modules = useSelector((state) => state.user.modules[orgId]);
  const currentOrganization = useSelector(
    (state) => state.user.organizations[orgId],
  );

  const logoUrl = currentOrganization?.Logo?.url;

  // Function to check if background from organization logo is transparent and if not, find background color;
  useEffect(() => {
    if (logoUrl) {
      const img = new Image();
      img.src = logoUrl;
      img.crossOrigin = "anonymous";

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const pixels = imageData.data;

        let transparentPixelCount = 0;
        let opaquePixelCount = 0;
        const colorCount = {};
        let maxCount = 0;
        let dominantColor = null;

        for (let i = 0; i < pixels.length; i += 4) {
          const alpha = pixels[i + 3];

          if (alpha < 255) {
            transparentPixelCount++;
          } else {
            opaquePixelCount++;
            const color = `${pixels[i]},${pixels[i + 1]},${pixels[i + 2]}`;
            colorCount[color] = (colorCount[color] || 0) + 1;

            if (colorCount[color] > maxCount) {
              maxCount = colorCount[color];
              dominantColor = color;
            }
          }
        }

        const totalPixels = transparentPixelCount + opaquePixelCount;
        const transparencyRatio = transparentPixelCount / totalPixels;

        const transparencyThreshold = 0.1;

        setHasTransparentBackground(transparencyRatio > transparencyThreshold);
        setBackgroundColor(
          transparencyRatio > transparencyThreshold
            ? "#FFFFFF"
            : `rgb(${dominantColor})`,
        );
        setImageLoaded(true);
        setIsLogoError(false);
      };

      img.onerror = () => {
        console.error("Failed to load logo image");
        setIsLogoError(true);
      };
    }
  }, [logoUrl]);

  const handleOpenOrganizationsSwitchModal = () => {
    dispatch(
      showModal({
        name: "organizations_switch_modal",
      }),
    );
  };

  const toggleMenuActive = () => {
    dispatch(updateMenuActive(!menuActive));
  };

  const handleHomepageNavigation = () => {
    navigate(`/${orgId}`);
  };

  const handleRefreshData = () => {
    dispatch(
      setLoadingContext({
        context: REFRESH_BUTTON_LOADER,
        isLoading: true,
      }),
    );
    dispatch(getOrganizationData(orgId, sessionToken, navigate, false)).finally(
      () => {
        dispatch(
          setLoadingContext({
            context: REFRESH_BUTTON_LOADER,
            isLoading: false,
          }),
        );
      },
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper}>
        <img
          src={LogoWhite}
          alt="Borderly logo"
          className={styles.logo}
          onClick={handleHomepageNavigation}
        />
        {logoUrl && !isLogoError && (
          <>
            <img src={PlusIcon} className={styles.plus} alt="Plus icon" />
            <div
              className={styles.imageWrapper}
              style={{
                backgroundColor: hasTransparentBackground
                  ? "#FFFFFF"
                  : backgroundColor,
              }}
            >
              {imageLoaded && (
                <img src={logoUrl} alt="Logo" className={styles.orgLogo} />
              )}
            </div>
          </>
        )}
      </div>
      <div className={styles.rightSide}>
        <Button
          text="Refresh data"
          icon={RefreshIcon}
          className={styles.refresh}
          onClick={handleRefreshData}
        />
        <div
          className={styles.orgName}
          onClick={handleOpenOrganizationsSwitchModal}
        >
          {currentOrganization?.Name}
        </div>
        {modules?.length > 0 && (
          <img
            ref={menuIconRef}
            src={MenuIcon}
            alt="Menu icon"
            className={classNames(styles.icon, menuActive && styles.active)}
            onClick={toggleMenuActive}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
