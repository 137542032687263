import React from "react";
import styles from "./Loader.module.scss";
import { RevolvingDot } from "react-loader-spinner";

const PageLoader = ({ text }) => {
  return (
    <div className={styles.pageLoader}>
      <RevolvingDot
        visible={true}
        height="80"
        width="80"
        color="#007940"
        ariaLabel="revolving-dot-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
      <span>{text}</span>
    </div>
  );
};

export default PageLoader;
