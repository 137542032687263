import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "../../icons/calendar.svg";

const DateRangeFilter = ({ dateRange, handleDateRangeChange, isClearable }) => {
  const [startDate, endDate] = dateRange;

  const CustomButton = React.forwardRef(({ value, onClick }, ref) => (
    <button type="button" className="customInput" onClick={onClick} ref={ref}>
      {value ? value : "Select Date Range"}
      <img src={CalendarIcon} alt="Calendar icon" className="calendarIcon" />
    </button>
  ));

  return (
    <DatePicker
      selectsRange
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => handleDateRangeChange(update)}
      dateFormat="dd/MM/yyyy"
      wrapperClassName="dateRangePicker"
      isClearable={isClearable}
      customInput={<CustomButton />}
    />
  );
};

export default DateRangeFilter;
