import React, { useEffect, useRef, useState } from "react";
import styles from "./FormikUpload.module.scss";
import RemoveIconGreen from "../../../icons/close.svg";
import RemoveIconYellow from "../../../icons/close-yellow.svg";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../utils/utils";

const FormikUpload = ({ greenIcon, yellowIcon, field, form, ...props }) => {
  const [fileNames, setFileNames] = useState([]);
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);

  const sessionToken = localStorage.getItem("session_token");
  const { orgId } = useParams();

  const handleChange = async (event) => {
    const files = Array.from(event.currentTarget.files);
    if (files.length > 0) {
      setUploading(true);
      const newFileNames = [];
      const newFileUrls = [];

      for (const file of files) {
        try {
          // Prepare form data
          const formData = new FormData();
          formData.append("org_id", orgId);
          formData.append("file", file);

          // Send file to the server
          const response = await axiosInstance.post("/s3/file", formData, {
            headers: {
              "session-token": sessionToken,
              "Content-Type": "multipart/form-data",
            },
          });

          // Collect the file name and URL
          newFileNames.push(file.name);
          newFileUrls.push(response.data);
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      }

      // Update the state and form field with the new files
      setFileNames((prevFileNames) => [...prevFileNames, ...newFileNames]);
      form.setFieldValue(field.name, [
        ...(form.values[field.name] || []),
        ...newFileUrls,
      ]);

      setUploading(false);
    }
  };

  const handleRemove = (index) => {
    const newFileNames = fileNames.filter((_, i) => i !== index);
    const newFileUrls = (form.values[field.name] || []).filter(
      (_, i) => i !== index,
    );

    setFileNames(newFileNames);
    form.setFieldValue(field.name, newFileUrls);

    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the input value to allow re-uploading
    }
  };

  // useEffect to reset fileNames when form value is cleared
  useEffect(() => {
    if (form.values[field.name].length === 0) {
      setFileNames([]); // Clear the file names
    }
  }, [form.values[field.name]]);

  return (
    <div className={styles.wrapper}>
      <input
        id={field.name}
        name={field.name}
        type="file"
        ref={fileInputRef}
        onChange={handleChange}
        multiple // Allow multiple files
        {...props}
        className={styles.uploadInput}
        disabled={uploading}
      />
      <label htmlFor={field.name} className={styles.uploadLabel}>
        {uploading ? "Uploading..." : "Choose File(s)"}
      </label>
      <div className={styles.fileInfo}>
        {fileNames.map((name, index) => (
          <div key={index} className={styles.fileItem}>
            <span className={styles.fileName}>{name}</span>
            {greenIcon && (
              <img
                src={RemoveIconGreen}
                className={styles.removeButton}
                onClick={() => handleRemove(index)}
                alt="Remove"
              />
            )}
            {yellowIcon && (
              <img
                src={RemoveIconYellow}
                className={styles.removeButton}
                onClick={() => handleRemove(index)}
                alt="Remove"
              />
            )}
          </div>
        ))}
      </div>
      {form.errors[field.name] && form.touched[field.name] && (
        <div className={styles.error}>{form.errors[field.name]}</div>
      )}
    </div>
  );
};

export default FormikUpload;
