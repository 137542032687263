import React, { useEffect, useState } from "react";
import Modal from "../components/Modal/Modal";
import styles from "./AuditsModal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "../components/Accordion/Accordion";
import { Formik, Form, Field } from "formik";
import axiosInstance from "../utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import Upload from "../components/UploadInput/Upload";
import Button from "../components/Button/Button";
import { hideModal, showModal } from "../redux/modalStore";
import Separator from "../components/Separator/Separator";
import classNames from "classnames";
import EditIcon from "../icons/edit.svg";
import { getOrganizationData } from "../actions/organization";
import Pill from "../components/Pill/Pill";
import Select from "../components/Select/Select";
import Table from "../components/Table/Table";
import SecondaryButton from "../components/Button/SecondaryButton";
import ModalSideBar from "../components/ModalSideBar/ModalSideBar";

const AuditsModal = ({ auditData }) => {
  const openedModal = useSelector((state) => state.modal.openedModal);
  const { linkedData } = useSelector((state) => state.organization);

  const openedModalData = auditData || openedModal?.data;

  const [loading, setLoading] = useState(false);
  const [editableData, setEditableData] = useState(openedModalData || {});
  const [unsavedChanges, setUnsavedChanges] = useState({});
  const [editField, setEditField] = useState(null);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [updates, setUpdates] = useState(openedModal?.data?.updates || []);

  const dispatch = useDispatch();
  const { orgId } = useParams();
  const navigate = useNavigate();
  const sessionToken = localStorage.getItem("session_token");

  useEffect(() => {
    setEditableData(openedModalData || {});
    setUnsavedChanges({});
    setEditField(null);
    setLoading(false);
    setUpdates(openedModal?.data?.updates || []);
  }, [openedModalData]);

  // Function to toggle sidebar visibility
  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  const modifyDescription = (description) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(description, "text/html");
    const links = doc.querySelectorAll("a");

    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });

    return doc.body.innerHTML;
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("org_id", orgId);
    formData.append("file", file);

    try {
      const response = await axiosInstance.post("/s3/file", formData, {
        headers: {
          "session-token": sessionToken,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      console.error("File upload error:", error);
      return null;
    }
  };

  const handleSaveResponses = async (values) => {
    setLoading(true);

    // Initialize flags and response object
    let hasChanges = false;
    const finalResponses = {};
    const fieldChanges = {};

    // Process each form item for changes
    for (const formItem of openedModalData.form || []) {
      const questions = formItem.questions;

      for (const questionId in questions) {
        const question = questions[questionId];
        const existingAnswer = question.answer || [];
        const newResponse = values.responses[questionId];

        if (Array.isArray(newResponse)) {
          const processedAnswers = await Promise.all(
            newResponse.map(async (answer) => {
              if (typeof answer === "string" && answer.trim() !== "") {
                return answer; // Return the text response as-is
              } else if (answer instanceof File) {
                const filePath = await uploadFile(answer);
                return filePath ? filePath : null; // Upload the file and return its path
              }
              return null;
            }),
          );

          // Filter out null values
          const nonNullAnswers = processedAnswers.filter(
            (answer) => answer !== null,
          );

          if (nonNullAnswers.length > 0 || existingAnswer.length > 0) {
            finalResponses[questionId] = [...existingAnswer, ...nonNullAnswers];
            hasChanges = true;
          }
        } else if (
          typeof newResponse === "string" &&
          newResponse.trim() !== ""
        ) {
          finalResponses[questionId] = [...existingAnswer, newResponse];
          hasChanges = true;
        } else if (existingAnswer.length > 0) {
          finalResponses[questionId] = existingAnswer;
        }
      }
    }

    // Check for changes in other fields
    ["responsible", "accountable", "consulted", "informed", "status"].forEach(
      (field) => {
        if (editableData[field] !== values[field]) {
          if (field === "responsible" && Array.isArray(values[field])) {
            fieldChanges[field] = values[field].join(", ");
          } else {
            fieldChanges[field] = values[field];
          }
          hasChanges = true;
        }
      },
    );

    // Only make a PATCH request if there are any changes
    if (hasChanges) {
      try {
        const payload = [
          {
            id: openedModalData.id,
            status: fieldChanges.status || openedModalData.status,
            responses: finalResponses,
            ...fieldChanges, // Include all field changes
          },
        ];

        const patchOptions = {
          method: "PATCH",
          url: `/nocodb/audit_tasks?org_id=${orgId}`,
          headers: {
            "session-token": sessionToken,
            "Content-Type": "application/json",
          },
          data: payload,
        };

        await axiosInstance.request(patchOptions);

        // Refresh organization data and close modal
        await dispatch(
          getOrganizationData(
            orgId,
            sessionToken,
            navigate,
            false,
            false,
            false,
            true,
          ),
        );

        setLoading(false);
        dispatch(hideModal());
      } catch (error) {
        console.error("Error saving responses:", error);
        setLoading(false);
      }
    } else {
      setLoading(false); // No changes to save, just set loading to false
    }
  };

  const initialValues = {
    responses: (openedModalData?.form || []).reduce((acc, formItem) => {
      Object.keys(formItem.questions).forEach((questionId) => {
        acc[questionId] = formItem.questions[questionId].answers || [""];
      });
      return acc;
    }, {}),
    responsible: Array.isArray(editableData.responsible)
      ? editableData.responsible.join(", ")
      : editableData.responsible || "",
    accountable: editableData.accountable || "",
    consulted: editableData.consulted || "",
    informed: editableData.informed || "",
    status: editableData.status || "Open",
  };

  const truncateText = (text, maxLength) => {
    if (text?.length <= maxLength) {
      return text;
    }
    return (
      <>
        {text?.substring(0, maxLength)}
        <span className={styles.readMore}>... read more</span>
      </>
    );
  };

  const columns = [
    {
      accessorKey: "key",
      header: "Key",
      size: 100,
      cell: (props) => <div>{props.getValue()}</div>,
    },
    {
      accessorKey: "description",
      header: "Description",
      size: 350,
      cell: (props) => <div>{truncateText(props.getValue(), 80)}</div>,
    },
    {
      accessorKey: "responsible",
      header: "Responsible",
      size: 200,
      cell: (props) => {
        return props.row.original.responsible ? (
          <div>{props.row.original.responsible}</div>
        ) : (
          <div>Not defined</div>
        );
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 120,
      cell: (props) => {
        const status = props.getValue();
        let statusClassName = "";

        switch (status) {
          case "Open":
            statusClassName = styles.openStatus;
            break;
          case "In Progress":
            statusClassName = styles.inProgressStatus;
            break;
          case "In Review":
            statusClassName = styles.inReviewStatus;
            break;
          case "Done":
            statusClassName = styles.resolvedStatus;
            break;
          default:
            break;
        }

        return (
          <div className={classNames(styles.statusCell, statusClassName)}>
            {status}
          </div>
        );
      },
    },
  ];

  const handleOnClose = () => {
    dispatch(hideModal());
  };

  const handleNavigationClick = (path) => {
    navigate(`/${orgId}/${path}`);
    handleOnClose();
  };

  const statusOptions = [
    { label: "Open", value: "Open" },
    { label: "In Progress", value: "In Progress" },
    { label: "In Review", value: "In Review" },
    { label: "Done", value: "Done" },
  ];

  const renderEmails = (emails, editField, fieldName) => {
    if (editField === fieldName) {
      return (
        <Field
          name={fieldName}
          component={Pill}
          placeholder="Enter emails"
          limit={5}
          parse={(value) => (Array.isArray(value) ? value.join(", ") : value)}
        />
      );
    }

    if (Array.isArray(emails)) {
      return emails.join(", ");
    } else if (typeof emails === "string") {
      return emails;
    }

    return null;
  };

  const handleOpenNewFindingModal = () => {
    dispatch(
      showModal({
        name: "new_finding_modal",
        data: openedModalData,
      }),
    );
  };

  return (
    <Modal name="audits_modal" title={openedModalData?.key} width="55vw">
      <div className={styles.content}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSaveResponses}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form className={styles.formikContent}>
              <div className={classNames(styles.firstRow, styles.spaceBetween)}>
                <div className={classNames(styles.row, styles.start)}>
                  <div className={styles.subheading}>Name:</div>
                  <div className={styles.bold}>{openedModalData?.name}</div>
                </div>
                <div className={styles.row}>
                  <Button
                    className={styles.newFindingButton}
                    text="Add new finding"
                    onClick={handleOpenNewFindingModal}
                  />
                  <Button
                    text="See Updates"
                    onClick={toggleSidebar}
                    className={styles.toggleSidebarButton}
                    type="Button"
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.title}>Status:</div>
                <div className={styles.statusContainer}>
                  {editField === "status" ? (
                    <Field
                      name="status"
                      component={Select}
                      options={statusOptions}
                      value={values.status}
                      onChange={(e) => {
                        setFieldValue("status", e.target.value);
                        setUnsavedChanges((prev) => ({
                          ...prev,
                          status: e.target.value,
                        }));
                        setEditField(null);
                      }}
                    />
                  ) : (
                    <>
                      <div
                        className={classNames(
                          styles.statusCell,
                          values.status === "In Progress"
                            ? styles.inProgressStatus
                            : values.status === "In Review"
                              ? styles.inReviewStatus
                              : values.status === "Done"
                                ? styles.resolvedStatus
                                : styles.openStatus,
                        )}
                      >
                        {values.status}
                      </div>
                      <img
                        src={EditIcon}
                        className={styles.editIcon}
                        onClick={() => setEditField("status")}
                        alt="Edit"
                      />
                    </>
                  )}
                </div>
              </div>
              {/* Editable Responsible Field */}
              <div className={styles.row}>
                <div className={styles.title}>Responsible:</div>
                <div className={styles.bold}>
                  {renderEmails(values.responsible, editField, "responsible")}
                </div>
                <img
                  src={EditIcon}
                  className={styles.editIcon}
                  onClick={() => setEditField("responsible")}
                  alt="Edit"
                />
              </div>

              {/* Editable Accountable Field */}
              <div className={styles.row}>
                <div className={styles.title}>Accountable:</div>
                <div className={styles.bold}>
                  {renderEmails(values.accountable, editField, "accountable")}
                </div>
                <img
                  src={EditIcon}
                  className={styles.editIcon}
                  onClick={() => setEditField("accountable")}
                  alt="Edit"
                />
              </div>

              {/* Editable Consulted Field */}
              <div className={styles.row}>
                <div className={styles.title}>Consulted:</div>
                <div className={styles.bold}>
                  {renderEmails(values.consulted, editField, "consulted")}
                </div>
                <img
                  src={EditIcon}
                  className={styles.editIcon}
                  onClick={() => setEditField("consulted")}
                  alt="Edit"
                />
              </div>

              {/* Editable Informed Field */}
              <div className={styles.row}>
                <div className={styles.title}>Informed:</div>
                <div className={styles.bold}>
                  {renderEmails(values.informed, editField, "informed")}
                </div>
                <img
                  src={EditIcon}
                  className={styles.editIcon}
                  onClick={() => setEditField("informed")}
                  alt="Edit"
                />
              </div>

              <Separator />

              {(editableData.form || []).map((formItem, index) => (
                <div key={index} className={styles.formItem}>
                  <div className={classNames(styles.row, styles.center)}>
                    <div className={styles.title}>Risk ID:</div>
                    <div className={styles.bold}>{formItem.risk.risk_id}</div>
                  </div>
                  <Accordion
                    items={Object.keys(formItem.questions).map((questionId) => {
                      const question = formItem.questions[questionId];
                      const currentAnswers = question.answer || [];

                      return {
                        question: question.question,
                        answer: {
                          type: question.type,
                          answers: currentAnswers,
                          questionId: questionId,
                          modifyDescription: modifyDescription,
                          editable: editableData.status !== "Done",
                        },
                      };
                    })}
                    openAllByDefault
                    icons
                    status={editableData?.status}
                  />
                </div>
              ))}
              {/* Save Button */}
              <Button
                text={loading ? "Saving..." : "Save Changes"}
                type="submit"
                className={styles.submitButton}
                disabled={loading}
              />
            </Form>
          )}
        </Formik>
        {linkedData?.linked_findings?.length > 0 && (
          <>
            <div className={styles.title}>Linked Findings</div>
            <div className={styles.table}>
              <Table
                columns={columns}
                data={linkedData?.linked_findings}
                className={styles.table}
              />
            </div>
            <SecondaryButton
              text="View all findings..."
              className={styles.secondaryButton}
              onClick={() => handleNavigationClick("findings")}
            />
          </>
        )}
        <ModalSideBar
          isOpen={isSidebarOpen}
          onClose={() => setSidebarOpen(false)}
          updates={updates}
          setUpdates={setUpdates}
          auditTaskId={openedModal?.data?.id}
        />
      </div>
    </Modal>
  );
};

export default AuditsModal;
